// /src/store/modules/songSearch.js
// import { timestampServer } from '@/firebase/config';
import { saveDocument, doesDocExist, fetchDocuments } from '@/services/firebaseServices';
import { makeSongId } from '@/services/showServices';

export default {
  namespaced: true,
  state: {
    allSongs: [], // Store for all songs from allSongsClean.json
    dataLoaded: false, // Flag to check if the data has been loaded
    searchResults: [],
    myRequests: [],
    savedSong: null,
    isLoading: false,
    error: null,
    lastVisible: null,
    isMore: false,
    song: null,
    songID: null,
  },
  mutations: {
    SET_SONGS_DATA(state, data) {
      state.allSongs = data;
      state.dataLoaded = true;
    },
    SET_SONG(state, song) {
      state.song = song;
    },
    SET_SONG_ID(state, songID) {
      state.songID = songID;
    },
    CLEAR_SEARCH_RESULTS(state) {
      state.searchResults = [];
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_LAST_VISIBLE(state, lastVisible) {
      // for firestore pagination of search results.
      state.lastVisible = lastVisible;
    },
    SET_SEARCH_RESULTS(state, results) {
      if (Array.isArray(results)) {
        // Assuming you want to replace the existing results
        state.searchResults = results;
      } else {
        // Handle the error appropriately, e.g., by clearing the existing results or setting a default value
        state.searchResults = [];
      }
    },
    SET_IS_MORE(state, isMore) {
      state.isMore = isMore;
    },
    SET_MY_REQUESTS(state, saveSong) {
      state.savedSong = saveSong;
    },
    SAVED_SONG(state, saveSong) {
      state.savedSong = saveSong;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    }
  },
  actions: {
    async ensureDataLoaded({ commit, state }) {
      if (!state.dataLoaded) {
        commit('SET_LOADING', true);
        try {
          const response = await fetch('/json/allSongsClean.json');
          if (!response.ok) throw new Error('Failed to load song data');
          const data = await response.json();
          commit('SET_SONGS_DATA', data);
        } catch (error) {
          commit('SET_ERROR', error.message);
        } finally {
          commit('SET_LOADING', false);
        }
      }
    },
    fetch25RandomSongs({ commit, state }) {
      commit('SET_LOADING', true);
      // Ensure data is loaded
      this.dispatch('songSearch/ensureDataLoaded').then(() => {
        const allSongs = state.allSongs;
        let randomSongs = [];
        for (let i = 0; i < 25; i++) {
          const randomIndex = Math.floor(Math.random() * allSongs.length);
          randomSongs.push(allSongs[randomIndex]);
        }
        commit('SET_SEARCH_RESULTS', randomSongs);
        commit('SET_LOADING', false);
      });
    },
    async searchKaraokeSongs({ commit, dispatch, state }, searchTerm ) {
      console.log('searchKaraokeSongs searchTerm',searchTerm);
      commit('SET_LOADING', true);
      await dispatch('ensureDataLoaded');
      if (!state.dataLoaded) {
        commit('SET_ERROR', 'Failed to load song data');
        return;
      }
      try {
        const normalizedSearchTerm = searchTerm.toLowerCase().replace(/&/g, ' and ').split(/\s+/).filter(Boolean); 
        const searchResults = state.allSongs.filter(song => {
          return normalizedSearchTerm.every(term => song.includes(term));
        });

        commit('SET_SEARCH_RESULTS', searchResults);
        commit('CLEAR_ERROR');
      } catch (error) {
        console.error('Error during song search:', error);
        commit('SET_ERROR', 'Search failed. Please try again later.');
      } finally {
        commit('SET_LOADING', false);
      }
    },
    clearSearchResults({ commit }) {
      commit('CLEAR_SEARCH_RESULTS');
    },
    async saveSongToMyList({ commit, rootState }, song) {
      const user = rootState.auth.user;
      const songID = makeSongId(song);

      commit('SET_SONG', song);
      commit('SET_SONG_ID', songID);

      if (!user || !user.email) {
        commit('SET_ERROR', 'User is not logged in or email is missing');
        return null;
      }
      
      let artist, title;
      if (typeof song !== 'string') {
        console.error('song is not a string:', song);
        commit('SET_ERROR', 'Song is not a string.')
        return null;
      } else {
          [artist, title] = song.split(' - ');
          artist = artist?.trim();
          title = title?.trim();
      }

      const songPath = `Singers/${user.email}/MyKaraokeList/${songID}`;

      try {
        const songExist = await doesDocExist(songPath);
        if (songExist) {
          commit('SET_ERROR', 'This song is already in your list.');
          // Optionally, you can also fetch and show when the song was added.
        } else {
          // Adding default song structure to the database
          const songToSave = {
            songID,
            song,
            artist,
            title,
            genre: [],
            lang: [],
            myRating: null,
            fav: false,
            duet: false,
            practice: false,
            sing: false,
            singSoon: false,
            myLists: [],
            hasRequests: false,
            myRequests: [],
            sang: false,
            sangAt: [],
            lastSangAt: null,
          };

          await saveDocument(songPath, songToSave,{ merge: true },'addedOn');
          commit('SAVED_SONG', songToSave);
          commit('CLEAR_ERROR');
        }
        return songID;
      } catch (error) {
        commit('SET_ERROR', 'Error saving song to Firestore: ' + error.message);
        console.log("songSearch saveSongToMyList say:",error.message);
        return null;
      }
    },
    async fetchUserKaraokeList({ commit, rootState }) {
      const userEmail = rootState.auth.user.email;
      if (!userEmail) {
        commit('SET_ERROR', 'User email is not available');
        return;
      }
      const path = `Singers/${userEmail}/MyKaraokeList`;
      try {
        const songs = await fetchDocuments(path);
        commit('SET_SONGS_DATA', songs); // Assuming you have a mutation to handle this
        commit('CLEAR_ERROR');
      } catch (error) {
        commit('SET_ERROR', error.message);
      }
    },
  },
  getters: {
    savedSong(state){
      return state.savedSong ? state.savedSong : 'No recently saved songs'
    },
    getSearchResults(state) {
      return state.searchResults;
    },
    isLoading(state) {
      return state.isLoading;
    },
    error(state) {
      return state.error;
    },
    getSongID(state){
      return state.songID;
    }
  },
};