// /src/firebase/config.js
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp, Timestamp } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions"; // Import Functions

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAe5ZttyT_6NTevW1p8ifg8QooQUyZeacU",
  authDomain: "kjpaul-demo.firebaseapp.com",
  projectId: "kjpaul-demo",
  storageBucket: "kjpaul-demo.appspot.com",
  messagingSenderId: "621101151993",
  appId: "1:621101151993:web:f2b3c15052556219195428",
  measurementId: "G-TLFXLV7WC4"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
const auth = getAuth();
const functions = getFunctions(); // Initialize Functions

// timestamps
const timestampServer = serverTimestamp()
const timestampDate = Timestamp.fromDate(new Date())

export { db, timestampDate, timestampServer, auth, functions, httpsCallable }