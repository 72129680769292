<template>
  <div>
    <h1>{{ isValidHost ? 'Host Profile' : 'Singer Profile' }}</h1>

    <p v-if="singerName">Singer: {{ singerName }}</p>
    <p v-else><router-link class="makeBtn" to="/addname">Please set your singer name.</router-link></p>
    <p>Logged in via email: {{ userEmail }}</p>

    <p v-if="isValidHost">You are logged in as a Host with email: {{ userEmail }}</p>
    <!-- Removed the atShow part for simplicity unless you need it specifically for this component -->

    <!-- Enable Hosting Button! -->
    <!-- <button v-if="isAuthenticated" class="fr" @click="showHost=!showHost">Become a Host</button> -->
    <br>
    <div v-if="showHost && isAuthenticated">
      <div v-if="isValidHost">
        <h1>You are now a Karaoke Host</h1>
        <p>
          Click on the Hamburger menu on the top left to see the hosting options. 
          If you do not see the hosting options swipe down to reload your browser.
        </p>
        <p>To start a show, click on "New Show" and fill out the form.</p>
        <h3>NOTE: This is for pre-beta testing only.</h3>
      </div>
      <div v-else>
        <form @submit.prevent="makeHost">
          <p><input type="text" v-model="hostName" placeholder="New Host/Stage Name (required)" required></p>
          <p><input type="text" v-model="hostCity" placeholder="City (required)" required></p>
          <p><input type="text" v-model="hostST" placeholder="two letter state abriviation (required)" required></p>
          <p><input type="text" v-model="hostVenue" placeholder="Show Location/Venue name (optional)"></p>
          <button type="submit">Create Host</button> <button class="fr" @click="showHost=!showHost">Find Karaoke!</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const showHost = ref(false);
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const hostName = ref('')
    const hostCity = ref('')
    const hostVenue = ref('')

    // Using computed properties to access the Vuex store's state and getters
    const user = computed(() => store.state.auth.user);
    const userEmail = computed(() => user.value?.email);
    const singerName = computed(() => store.getters['userDetails/singerName']);
    const isValidHost = computed(() => store.getters['userDetails/isHost']);

    const makeHost = async () => {
      const hostData = {
        email: userEmail.value,
        hostName: hostName.value,
        city: hostCity.value,
        // ST: hostST.value, // Maybe add back in under create host only
        venues: [hostVenue.value],
        isHost: true,
      }
      console.log('hostData',hostData);
      await store.dispatch('hosts/makeHost', hostData);
    }


    return {
      userEmail,
      singerName,
      isValidHost,
      showHost,
      isAuthenticated,
      makeHost,
      hostName,
      hostCity,
      hostVenue,
    };
  },
};
</script>

<style scoped>
    h1 {
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 10px;
        color:gold;
        font-weight: bold;
    }
</style>