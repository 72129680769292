<template>
  <div @click.stop>
    <div @click="showMoreOptions(result)">
      <div class="littleBtn">
        <span v-if="!seeMore" class="material-icons" style="color: darkorange;">expand_circle_down</span>
        <span v-else class="material-icons" style="color: darkorange;">close</span>
        &nbsp;<div class="raisedText">More Options</div>
      </div>
    </div>
    <div class="moreOptions" v-if="seeMore">
      <div>
          <SaveToMyList
            @signupSuccess="$emit('signupSuccess')" 
            :result="result" 
            :class="{ 'needsLogin': !isAuthenticated }" />
      </div>
      <div v-if="artist" @click="moreByArtist(artist)">
        <div class="littleBtn">
          <span class="material-icons" style="color: gold;">person</span>
          <div class="raisedText">More by this Artist</div>
        </div>
      </div>
      <div v-if="user && !submitRequestClicked && !error" @click="requestForFriend(result)">
        <div class="littleBtn">
          <span class="material-icons" style="color: steelblue;">diversity_1</span>
          <div class="raisedText">Request For friend</div>
        </div>
      </div>
      <div v-if="user && submitRequestClicked && !error">
        <div v-if="!submitRequestCancelled"><b>Request For friend Submitted.</b></div>
        <div v-else><b>Request cancelled.</b></div>
      </div>
      <div>
        <Practice :result="result" />
      </div>
    </div>
    <div class="error" v-if="error">{{ error }}</div>
    <div v-if="loading">Loading...</div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import SaveToMyList from '../components/SaveToMyList.vue';
import Practice from '@/components/Practice.vue';

export default {
  name: 'MoreOptions',
  emits: ['signupSuccess'],
  props: ['result'],
  components: {
    SaveToMyList,
    Practice,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const seeMore = ref(false);

    const user = computed(() => store.getters['auth/getUser']);
    const loading = ref(false);
    const artist = ref('');
    const title = ref('');
    const error = ref(null);
    const submitRequestClicked = ref(false);
    const submitRequestCancelled = ref(false);

    const showSongOptions = ref(false);
    const songOptions = ref({
      fav: false,
      sing: false,
      singSoon: false,
      myRating: null,
    });

    const updateSongOptions = () => {
      console.log('Updating song options:', songOptions.value);
    };

    const showMoreOptions = (result) => {
      if (typeof result === 'string') {
        [artist.value, title.value] = result.split(' - ').map(part => part.trim());
      } else {
        artist.value = result.artist;
        title.value = result.title;
      }
      seeMore.value = !seeMore.value;
    };

    const fetchData = async () => {
      if (user.value) {
        loading.value = true;
        await Promise.all([
          store.dispatch('userDetails/fetchUserDetails'),
          store.dispatch('shows/fetchCurrentShow'),
        ]);
        loading.value = false;
      }
    };

    watch(user, fetchData);

    const requestForFriend = async (result) => {
      submitRequestClicked.value = true;
      const friendName = prompt("Enter your friend's name:");
      if (!friendName){ 
        submitRequestCancelled.value = true;
        return;
      }

      try {
        await store.dispatch('shows/submitSongRequest', {
          song: result,
          singerName: friendName,
        });
      } catch (err) {
        console.error('Request submission failed:', err);
      }
    };

    const moreByArtist = (artistName) => {
      if (!artistName) {
        error.value = 'Artist name is required for more songs search.';
        console.error("Attempted to navigate to 'SearchResults' without an artist name.");
        return;
      }

      loading.value = true;
      try {
        router.push({ name: 'SearchResults', params: { artist: artistName } });
        error.value = null;
      } catch (err) {
        console.error('Router navigation error:', err);
        error.value = "Failed to navigate to artist's songs.";
      } finally {
        loading.value = false;
      }
    };

    return {
      user,
      error,
      showMoreOptions,
      showSongOptions,
      updateSongOptions,
      songOptions,
      requestForFriend,
      submitRequestClicked,
      submitRequestCancelled,
      moreByArtist,
      artist,
      title,
      seeMore,
      loading,
    };
  },
};
</script>

<style scoped>
.moreOptions div div {
  margin-left: 20px;
  color: burlywood;
  text-decoration: none;
}

</style>
