<template>
  <div>
    <h1>Sing Soon Songs</h1>
    <ul v-if="songs.length">
      <li v-for="(song, index) in songs" :key="index" @click="toggleSubList(index)">
        {{ song.song }}
        <div>
          <SubList v-if="song.showSubList" :result="song.song" @signupSuccess="handleSignupSuccess" />
        </div>
      </li>
    </ul>
    <div v-else>
      <p>No songs marked as 'Sing Soon'.</p>
    </div>
  </div>
</template>

<script>
// /src/components/lists/SingSoonList.vue
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import SubList from '@/components/SubList.vue';

export default {
  components: { SubList },
  setup() {
    const store = useStore();
    const songs = computed(() => store.getters['lists/getUserKaraokeList']); // Adjust if using a separate getter for singSoonSongs

    onMounted(async () => {
      store.dispatch('lists/fetchSongList', 'singSoon');
    });

    const toggleSubList = (index) => {
      // Toggle functionality remains the same
      songs.value[index].showSubList = !songs.value[index].showSubList;
    };

    const handleSignupSuccess = () => {
      // Handle signup success logic here, possibly refetching the songs or updating the UI
      store.dispatch('lists/fetchSongList', 'singSoon');
    };

    return { 
      songs, 
      toggleSubList, 
      handleSignupSuccess 
    };
  },
};
</script>


<style>

</style>