<template>
  <div class="list-options" id="listOptions">
    <h3>{{ titleText }}</h3>
    <div v-if="showForm">
      <form @submit.prevent="updateSongOptions">
        <h4 class="center">{{ options.song }}</h4>

        <div class="option">
          <input type="checkbox" id="fav" v-model="options.fav">
          <label for="fav">Favorite</label>
        </div>
        <div class="option">
          <input type="checkbox" id="duet" v-model="options.duet">
          <label for="duet">Duet</label>
        </div>
        <div class="option">
          <input type="checkbox" id="practice" v-model="options.practice">
          <label for="practice">Practice</label>
        </div>
        <div class="option">
          <input type="checkbox" id="sing" v-model="options.sing">
          <label for="sing">Sing</label>
        </div>
        <div class="option">
          <input type="checkbox" id="singSoon" v-model="options.singSoon">
          <label for="singSoon">Sing Soon</label>
        </div>
        <div id="myRating" class="option radio-options">
          My Rating:
          <div v-for="n in 5" :key="n" class="radioLabel">
            <input type="radio" v-model="options.myRating" :value="n">{{ n }}
          </div>
        </div>
        <div class="option">
          <input type="text" id="customListName" v-model="customListName" placeholder="Make Custom List">
        </div>
        <div class="option" v-for="list in customLists" :key="list">
          <input type="checkbox" v-model="options.myLists" :value="list">
          <label>{{ list }}</label>
        </div>
        <button type="submit" class="makeBtn">Save Options</button>
        <button v-if="deleteEnabled" type="button" class="makeBtn deleteBtn fr" @click="deleteSong">Delete Song</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';


export default {
  name: 'ListOptions',
  props: {
    songId: String,
  },
  setup(props) {
    const store = useStore();
    const options = ref({
      fav: false,
      sing: false,
      duet: false,
      practice: false,
      singSoon: false,
      myRating: null,
      myLists: [],
    });
    const customListName = ref('');
    const customLists = ref([]);
    const titleText = ref('List Options');
    const showForm = ref(true);
    const route = useRoute();



    watch(customListName, (newVal) => {
      if (newVal && !customLists.value.includes(newVal)) {
        customLists.value.push(newVal);
      }
    });

    onMounted(async () => {
      await store.dispatch('lists/fetchSongDetails', props.songId); 
      const currentSongDetails = store.getters['lists/getCurrentSongDetails']; 
      if (currentSongDetails) {
        options.value = { ...options.value, ...currentSongDetails };
      }
      document.getElementById("fav").focus();
    });

    const updateSongOptions = async () => {
      console.log('Updating song options for', props.songId, options.value);
      // Update song with new options
      await store.dispatch('lists/updateUserKaraokeSong', { songID: props.songId, updates: options.value });
      titleText.value = "Song options updated!";
      showForm.value = false;
    };

    const deleteSong = async () => {
      // Confirm before deleting
      if (confirm("Are you sure you want to delete this song from your list?")) {
        console.log('Deleting song with ID:', props.songId);
        await store.dispatch('lists/deleteUserKaraokeSong', props.songId);
        titleText.value = "Song deleted!";
        showForm.value = false;
        // Optionally, redirect or inform the user of successful deletion
      }
    };

    const deleteEnabled = computed(() => {
      // Check if the current path is /lists or starts with /lists/
      if (route.path === '/lists' || route.path.startsWith('/lists/')) {
        return true;
      }
      return false;
    });

    return {
      options,
      titleText,
      showForm,
      customListName,
      customLists,
      deleteEnabled,
      deleteSong,
      updateSongOptions,
    };
  },
};
</script>

<style scoped>

h3 {
  margin-left: 10px;
  color: var(--twdarkc3);
}
h4 {
  color: var(--twdarkc4);
  margin-bottom: 10px;
  text-transform: capitalize; 
}
.list-options form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--twdarkc3);
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-family: 'Nunito', sans-serif;
  color: black;
}

.list-options form div.option {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Corrected */
  margin-bottom: 15px;
}

.list-options form div.option input[type="checkbox"],
.list-options form div.option input[type="radio"] {
  margin-right: 10px;
  margin-left: 20px;
}

.list-options form div.option label {
  color: black !important; /* Ensuring color is applied */
}

/* Ensure radio buttons and checkboxes align correctly */
.list-options form div.radio-options, .list-options form div.option {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Ensure alignment is to the start */
}

#customListName {
  border: 1px solid black !important;
  background-color: white !important;
  color: black !important;
  width: 100%;
  padding: 5px;
}

.list-options form div.options .save-btn {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block; /* Ensure it's a block element */
  margin-left: 20px; /* Align with other inputs */
  margin-top: 20px !important;
}

.list-options form div.options .save-btn:hover {
  background-color: #45a049;
}

 #myRating, #myRating div {
  color: black;
}
</style>
