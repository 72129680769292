<template>
  <div>
    <h1>My Karaoke Lists</h1>
    <ul v-if="songs.length">
      <li v-for="(song, index) in songs" :key="index" @click="toggleSubList(index)">
        {{ song.song }}
        <div>
          <SubList v-if="song.showSubList" :result="song.song" @signupSuccess="handleSignupSuccess" />
        </div>
      </li>
    </ul>
    <div v-else>No songs in your karaoke list.</div>
  </div>
</template>

<script>
// /src/components/lists/AllList.vue
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import SubList from '@/components/SubList.vue';

export default {
  components: { SubList },
  setup() {
    const store = useStore();
    const songs = computed(() => store.getters['lists/getUserKaraokeList']);
    
    onMounted(async () => {
      // Corrected the dispatch call to include the namespace 'lists/'
      await store.dispatch('lists/fetchUserKaraokeList');
      console.log('songs.value[0]?.song',songs.value[0]?.song); // Log the first song object to inspect its structure
    });

    const toggleSubList = (index) => {
      songs.value[index].showSubList = !songs.value[index].showSubList;
    };

    const handleSignupSuccess = () => {
      // Re-fetch user's karaoke list to reflect any changes after signup
      store.dispatch('lists/fetchUserKaraokeList');
    };

    watch(songs, (newVal) => {

      console.log('newVal', newVal);
    });

    return { 
      songs, 
      toggleSubList, 
      handleSignupSuccess 
    };
  },
};
</script>
