// src/store/modules/hosts.js
import { 
  toTitleCase,
  showDetailsAllHosts } from '@/services/showServices';
import { 
  updateDocument, 
  fetchDocument, 
  fetchDocuments,
  saveDocument,
  subscribeToCollection, 
  deleteDocument,
  updateArray, 
  addDocAutoID} from '@/services/firebaseServices';

export default {
  namespaced: true, // Enable namespaced module
  state: () => ({
    hostEmail: null,
    hostName: null,
    hostInfo: null,
    QRID: null,
    hostsInCity: [],
    hostsInST: [],
    isHost: null,
    hosts: [],
    currentHost: null,
    requests: null,
    requestsPaused: false,
    showHistory: [],
    karaokeProviders: ['karaoke-version'],
    defaultKaraokeProviders: [],
    error: null,
  }),
  mutations: {
    SET_HOST_INFO(state, hostInfo) {
      state.hostInfo = hostInfo;
    },
    SET_IS_HOST(state, hostInfo) {
      state.isHost = hostInfo.isHost;
    },
    SET_HOST_EMAIL(state, email) {
      state.hostEmail = email;
    },
    SET_QRID(state, QRID) {
      state.QRID = QRID;
    },
    SET_CURRENT_HOST(state, hostInfo) {
      state.hostInfo = hostInfo;
    },
    SET_HOST_NAME(state, hostName) {
      state.hostName = hostName;
    },
    SET_HOSTS_IN_CITY(state, hosts) {
      console.log("Setting hosts in City:", hosts);
      state.hostsInCity = hosts;
    },
    SET_HOSTS_IN_ST(state, hosts) {
      state.hostsInST = hosts;
    },
    SET_HOSTS_AT_VENUE(state, hosts) {
      console.log("Setting hosts at venue:", hosts);
      state.hostsAtVenue = hosts;
    },
    SET_REQUESTS(state, requests) {
      state.requests = requests;
    },
    SET_REQUESTS_UNSUBSCRIBE(state, unsubscribe) {
      // Store the unsubscribe function to be able to stop listening
      state.unsubscribeRequestsListener = unsubscribe;
    },
    SET_PAUSE_REQUESTS(state, pause) {
      state.requestsPaused = pause;
    },
    SET_SHOW_HISTORY(state, shows) {
      state.showHistory = shows;
      console.log("Updated show history in Vuex:", state.showHistory);
    },    
    SET_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async fetchHostInfo({ commit, state }) {
      if (!state.hostEmail) {
        console.error("Host email is not set.");
        commit('SET_ERROR', 'Host email is not set.');
        return;
      }
    
      try {
        const hostInfo = await fetchDocument(`Hosts/${state.hostEmail}`);
        if (hostInfo) {
          console.log("host.js fetchHostInfo hostInfo.hostName:",hostInfo.hostName);
          commit('SET_HOST_INFO', hostInfo);
          commit('SET_HOST_NAME', hostInfo.hostName);
          commit('SET_QRID', hostInfo.QRID);
        } else {
          console.error("Host document does not exist.");
          commit('SET_ERROR', 'Host document does not exist.');
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
        console.error("Error fetching host info:", error);
      }
    },
    updateHostEmail({ commit }, email) {
      commit('SET_HOST_EMAIL', email);
    },
    updateCurrentHost({ commit }, host) {
      commit('SET_CURRENT_HOST', host);
    },
    async updateRequest({commit, rootState}, {requestId, songID, singerEmail, data, TSname} ) {

      console.log('updateRequest: ',requestId, songID, singerEmail, data, TSname);
      try {
        const showID = rootState.shows.showID;
        const path = `KaraokeShows/${showID}/Requests/${requestId}`;
  
        await updateDocument(path, data, TSname )
      } catch (error) {
        console.error('updateRequest failed:', error.message);
        commit('SET_ERROR', error.message);
      }

      try {
        const myListSongPath = `Singers/${singerEmail}/MyKaraokeList/${songID}`
        await updateDocument(myListSongPath, {sang:true}, 'lastSangAt')
        const sangAtData = [{
          date: new Date(),
          requestId
        }]
        await updateArray(myListSongPath,'sangAt', sangAtData);
        console.log('myListSongPath:',myListSongPath);
        const myRequestPath = `Singers/${singerEmail}/MyRequests/${requestId}`
        await updateDocument(myRequestPath, {sang:true}, 'sangAt')
        console.log('myRequestPath:',myRequestPath);
      } catch (error) {
        console.error('updateRequest failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async endShow({ commit, state }) {
      if (!state.hostEmail) return;

      try {
        await updateDocument(`Hosts/${state.hostEmail}/Shows/currentShow`, { isActive: false });
        commit('SET_AT_SHOW', false);
        commit('CLEAR_ERROR');
      } catch (error) {
        console.error('Ending show failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async togglePause({ commit, state }) {
      if (!state.hostEmail) return;

      try {
        await updateDocument(`Hosts/${state.hostEmail}/Shows/currentShow`, { requestsPaused: !state.requestsPaused });
        commit('SET_PAUSE_REQUESTS', !state.requestsPaused);
        commit('CLEAR_ERROR');
      } catch (error) {
        console.error('Toggling pause failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async fetchRequests({ commit }, showID) {
      try {
        // Assuming each show's requests are stored in a path like `KaraokeShows/${showID}/Requests`
        const path = `KaraokeShows/${showID}/Requests`;
        const requests = await fetchDocuments(path);
        commit('SET_REQUESTS', requests);
      } catch (error) {
        console.error('Fetching requests failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    watchRequests({ commit }, showID) {
      try {
        const path = `KaraokeShows/${showID}/Requests`;
  
        // Call the subscribeToCollection utility function
        const unsubscribe = subscribeToCollection(path, (requests) => {
          // Update the state with the latest requests
          commit('SET_REQUESTS', requests);
        });
  
        // Optionally store the unsubscribe function to stop listening later
        commit('SET_REQUESTS_UNSUBSCRIBE', unsubscribe);
      } catch (error) {
        console.error('Error setting up request watcher:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async fetchHostsInCity({ commit }, city) {
      try {
        const path = 'Hosts';
        const formattedCity = toTitleCase(city);
        console.log(`Fetching hosts in city: ${formattedCity}`);
        const hosts = await fetchDocuments(path, [['city', '==', formattedCity], ['isHost', '==', true]]);
        console.log('Fetched hosts:', hosts);
        if (hosts.length > 0) {
          const activeHosts = await showDetailsAllHosts(hosts);
          console.log('Fetched activeHosts:', activeHosts);
          commit('SET_HOSTS_IN_CITY', activeHosts.filter(host => host.isActive));
        } else {
          commit('SET_HOSTS_IN_CITY', []); // Handle the case where no hosts are found
        }
      } catch (error) {
        console.error('Fetching hosts in city failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async fetchHostsInST({ commit }, ST) {
      try {
        const path = 'Hosts';
        const hosts = await fetchDocuments(path, [['ST', '==', ST.toUpperCase()], ['isHost', '==', true]]);
        if (hosts.length > 0) {
          const activeHosts = await showDetailsAllHosts(hosts);
          commit('SET_HOSTS_IN_ST', activeHosts);
        } else {
          commit('SET_HOSTS_IN_ST', []);
        }
      } catch (error) {
        console.error('Fetching hosts in State failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async fetchHostsAtVenue({ commit }, venue) {
      try {
        const path = 'Hosts';
        const formattedVenue = toTitleCase(venue);
        const hosts = await fetchDocuments(path, [['venues', 'array-contains', formattedVenue], ['isHost', '==', true]]);
        if (hosts.length > 0) {
          const activeHosts = await showDetailsAllHosts(hosts);
          commit('SET_HOSTS_AT_VENUE', activeHosts);
        } else {
          commit('SET_HOSTS_AT_VENUE', []);
        }
      } catch (error) {
        console.error('Fetching hosts at venue failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async makeHost({ commit, rootState }, hostData) {
      console.log('hosts.js hostData',hostData)
      console.log('hostEmail: rootState.auth.user.email:', rootState.auth.user.email);
      try {
        console.log('hosts.js hostData',hostData);
        const showRef = await addDocAutoID('QRID',{hostEmail: rootState.auth.user.email}, 'createdAt');
        console.log('showRef.id:',showRef.id);
        hostData.QRID = showRef.id;

        const path = `Hosts/${rootState.auth.user.email}`;
        await saveDocument(path, hostData, {merge: true}, 'createdAt');
        const userPath =`Singers/${rootState.auth.user.email}`
        await updateDocument(userPath, {isHost: true});
        commit('SET_HOST_INFO', hostData);
        commit('SET_HOST_EMAIL', hostData.email);
        commit('SET_HOST_NAME', hostData.hostName);
        commit('SET_IS_HOST', hostData.isHost);
        commit('SET_QRID', showRef.id)
      } catch (error) {
        console.error('make Host failed:', error.message);
        commit('SET_ERROR', error.message);
      }

    },
    async deleteShow({ commit, state }, { path, showID }) {
      try {
        await deleteDocument(path);  // Use the deleteDocument function from your Firebase services
        // Optionally remove the show from local state to update the UI immediately
        const updatedShows = state.showHistory.filter(show => show.showID !== showID);
        commit('SET_SHOW_HISTORY', updatedShows);
      } catch (error) {
        console.error("Failed to delete show:", error);
        commit('SET_ERROR', error.message);
      }
    },
    async fetchShows({ commit }, hostEmail) {
      try {
        const path = `Hosts/${hostEmail}/Shows`;
        console.log('fetchShows path:',path);
        const shows = await fetchDocuments(path);
        console.log('fetchShows shows:',shows);
        commit('SET_SHOW_HISTORY', shows);  // Assuming you have a mutation for setting shows
      } catch (error) {
        console.error('Fetching shows failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
  },
  getters: {
    hostInfo: state => state.hostInfo,
    hostName: state => state.hostName,
    hostEmail: state => state.hostEmail,
    getCurrentHost: state => state.currentHost,
    getRequests: state => state.requests,
    getHostsInCity: state => state.hostsInCity || [],
    getHostsInST: state => state.hostsInST || [],
    getHostsAtVenue: state => state.hostsAtVenue || [],
    karaokeProviders: state => state.karaokeProviders,
    requestsPaused: state => state.requestsPaused,
    getShowHistory: state => state.showHistory,
    getAShow: (state) => (showID) => {
      return state.requests.filter(request => request.showID === showID);
    },
    getQRID: state => state.QRID,
    error: state => state.error,
  },
}