<template>
  <div>
    <h3>Now singing: {{ nowSinging }}</h3>
    <button v-if="!showID" @click="startShow">Start Show</button>
    <button @click="toggleHiddenSingersView">{{ showHiddenSingersText }}</button>
    
    <!-- Display Hidden Singers -->
    <div v-if="showHiddenSingers">
      <h4>Hidden Singers</h4>
      <ul>
        <li v-for="singerId in hiddenSingers" :key="singerId">
          {{ getSingerName(singerId) }}
          <button @click="reinstateSinger(singerId)">Reinstate Singer</button>
        </li>
      </ul>
    </div>
    
    <!-- Display Active Singers -->
    <div v-else>
      <ul v-if="visibleGroups && visibleGroups.length">
        <li v-for="group in visibleGroups" :key="group.singerName + group.singerEmail">
          <!-- Singer and toggle buttons -->

          <span v-if="group?.sung.length != 0 && group?.unsung.length != 0">
            {{ group.singerName }} - 
            {{ group.unsung.length }} requests - 
            {{ group.waitTime }} min
          </span>
          <span class="zeroRequests" v-else-if="group?.unsung.length == 0">
            {{ group.singerName }} - 
            {{ group.unsung.length }} requests - 
            {{ group.waitTime }} min
          </span>
          <span class="newSinger" v-else>
            {{ group.singerName }} - 
            {{ group.unsung.length }} requests - 
            {{ group.waitTime }} min
          </span>
          <button @click="toggleVisibility(group.singerName + '-' + group.singerEmail, 'unsung')">
            {{ visibility[group.singerName + '-' + group.singerEmail]?.unsung ? 'Hide Requests' : 'Show Requests' }}
          </button>
          <button @click="toggleVisibility(group.singerName + '-' + group.singerEmail, 'sung')">
            {{ visibility[group.singerName + '-' + group.singerEmail]?.sung ? 'Hide Sung' : 'Show Sung' }}
          </button>
          <button @click="hideSinger(group.singerName + '-' + group.singerEmail)">
            Hide Singer
          </button>
          
          <div v-if="group.singerName">
            <!-- Unsung songs list -->
            <ul v-if="visibility[group.singerName + '-' + group.singerEmail]?.unsung">
              <li v-for="request in group.unsung" :key="request.id">
                <span v-if="!request.skip">
                  <span v-if="request.singNext" class="singNext">NEXT: </span>
                  <strong>{{ request.song }}</strong>
                  <button @click="copyToClipboard(request.song)">Copy</button>
                  <button @click="markAsSung(request.id, request.songID, group.singerName, group.singerEmail, request.song)">Mark as Sung</button>
                  <button @click="removeSong(request.id, true)">Remove</button>
                </span>
              </li>
            </ul>
          </div>

          <!-- Sung songs list -->
          <div v-if="group.singerName">
            <ul v-if="visibility[group.singerName + '-' + group.singerEmail]?.sung">
              <li>
                <div  class="sung" v-for="sungSong in group.sung" :key="sungSong.id">
                  {{ sungSong.song }} (sung at {{ formatFirestoreTimestampToTime(sungSong.sangAt) }})
                </div>
                <div v-for="request in filterUnsungRequests(group.unsung)" :key="request.id">
                  <span  class="removed" v-if="request.skip">
                    {{ request.song }} (Removed) 
                    <button @click="copyToClipboard(request.song)">Copy</button>
                    <button @click="markAsSung(request.id, request.songID, group.singerName, group.singerEmail, request.song)">Mark as Sung</button>
                    <button @click="removeSong(request.id, false)">Reinstate</button>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div v-else>
        Loading or no data available.
      </div>
    </div>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { formatFirestoreTimestampToTime } from '@/services/firebaseServices';

// TODO: sort requests sent by a singer by oldest request at the top.

export default {
  name: 'HostShow',
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.state.auth.user);
    const isAuthenticated = computed(() => !!user.value);
    const visibility = ref({}); // Tracks visibility of each singer's song list
    const nowSinging = ref('');
    const showID = computed(() => store.state.shows.showID || false); 
    const error = computed(() => store.state.shows.error); 
    const requests = computed(() => store.getters['hosts/getRequests'] || []);
    const sortedGroups = computed(() => processAndSortRequests(requests.value));
    const isHost = computed(() => store.getters['userDetails/isHost']);
    const singerVisibility = ref({});
    const showAllSingers = ref(false);
    const tempHiddenSingers = ref([]);
    const hiddenSingers = ref([]);
    const showHiddenSingers = ref(false);
    const showHiddenSingersText = computed(() => showHiddenSingers.value ? 'Show Active Singers' : 'Show Hidden Singers');
    const singersData = ref(null)

    // This computed property now simply filters out hidden singers from the sortedGroups
    const visibleGroups = computed(() => {
      if (showHiddenSingers.value) {
        // If showing hidden singers, just return an empty array to not display any active singers
        return [];
      } else {
        // Normal behavior: filter out hidden singers
        return sortedGroups.value.filter(group => !hiddenSingers.value.includes(group.singerName + '-' + group.singerEmail));
      }
    });

    // A computed property to get the list of hidden singers for display
    const toggleHiddenSingersView = () => {
      showHiddenSingers.value = !showHiddenSingers.value;
    };

    const hideSinger = (singerKey) => {
      const index = hiddenSingers.value.indexOf(singerKey);
      if (index === -1) {
        hiddenSingers.value.push(singerKey);
      } else {
        hiddenSingers.value.splice(index, 1);
      }
    };

    const reinstateSinger = (singerId) => {
      const index = hiddenSingers.value.indexOf(singerId);
      if (index !== -1) {
        hiddenSingers.value.splice(index, 1);
      }
    };

    const getSingerName = (singerId) => {
      // Since you only care about the singer's name, this splits the ID and returns the name part.
      const [singerName] = singerId.split('-');
      return singerName;
    };

    // Example toggle function that ensures reactivity
    const toggleHiddenSingers = () => {
      if (showAllSingers.value) {
        // Temporarily show all singers
        showAllSingers.value = false;
        hiddenSingers.value = [...tempHiddenSingers.value]; // Restore hidden singers from temporary storage
      } else {
        // Hide all singers again
        showAllSingers.value = true;
        tempHiddenSingers.value = [...hiddenSingers.value]; // Store current hidden singers temporarily
        hiddenSingers.value = []; // Clear hidden singers list to show all
      }
    };

    const singerButtonText = (singerKey) => {
      return hiddenSingers.value.includes(singerKey) ? "Show Singer" : "Hide Singer";
    };

    // const hideSinger = (singerKey) => {
    //   const index = hiddenSingers.value.indexOf(singerKey);
    //   if (index === -1) {
    //     hiddenSingers.value.push(singerKey); // Reactively add to the list
    //   } else {
    //     hiddenSingers.value.splice(index, 1); // Reactively remove from the list
    //   }
    // };

    // const visibleGroups = computed(() => {
    //   return sortedGroups.value.filter(group => 
    //     !hiddenSingers.value.includes(group.singerName + '-' + group.singerEmail)
    //   );
    // });

    // Call watchRequests when the component is mounted
    onMounted(() => {
      if (showID.value) {
        store.dispatch('hosts/watchRequests', showID.value);
      }
    });

    // Unsubscribe from listening to requests when the component is unmounted
    onUnmounted(() => {
      const unsubscribe = store.state.hosts.requestsUnsubscribe;
      if (unsubscribe) {
        unsubscribe();
        // You might want to clear the unsubscribe function from the store after calling it
        store.commit('shows/CLEAR_REQUESTS_UNSUBSCRIBE');
      }
    });

    const startShow = async () => {
      if (!showID.value) {
        router.push({ name: 'NewShow' });
      } else {
        console.log('showID.value',showID.value);
        store.dispatch('hosts/watchRequests', showID.value);
      }
    };

    const processAndSortRequests = (documents) => {
      const now = new Date();
      const groups = {};

      documents.forEach(doc => {
        const key = `${doc.singerName}-${doc.userEmail}`;
        if (!groups[key]) {
          groups[key] = { unsung: [], sung: [], firstRequestTime: null, lastSungTime: null };
        }

        const createdAtDate = doc.createdAt?.toDate ? doc.createdAt.toDate() : new Date(doc.createdAt);
        const sangAtDate = doc.sangAt && doc.sang ? doc.sangAt.toDate ? doc.sangAt.toDate() : new Date(doc.sangAt) : null;

        if (doc.sang) {
          groups[key].sung.push(doc);
          // Update lastSungTime if sangAt is later than the current lastSungTime
          if (!groups[key].lastSungTime || (sangAtDate && sangAtDate > groups[key].lastSungTime)) {
            groups[key].lastSungTime = sangAtDate;
          }
        } else {
          groups[key].unsung.push(doc);
          // Update firstRequestTime if createdAt is earlier than the current firstRequestTime
          if (!groups[key].firstRequestTime || createdAtDate < groups[key].firstRequestTime) {
            groups[key].firstRequestTime = createdAtDate;
          }
        }
      });

      // Now map and sort the groups based on calculated wait times
      return Object.entries(groups).map(([key, group]) => {
        const [singerName, singerEmail] = key.split('-');
        const waitTime = group.lastSungTime
          ? calculateTimeDifference(now, group.lastSungTime)
          : group.firstRequestTime
          ? calculateTimeDifference(now, group.firstRequestTime)
          : 0;

        return {
          singerName,
          singerEmail,
          unsung: group.unsung,
          sung: group.sung,
          waitTime,
        };
      }).sort((a, b) => b.waitTime - a.waitTime); // Sort by waitTime in descending order
    };

    // request.id, group.songID, group.singerName, group.singerEmail, request.song
    const markAsSung = async (requestId, songID, singerName, singerEmail, songArtistTitle) => {
      try {
        if(!showID.value){
          console.log('Mark as sung says: No showID');
          throw error.value = "No showID"
        }
        await store.dispatch('hosts/updateRequest', {requestId, songID, singerEmail, data: {sang: true}, TSname: 'sangAt'});

        nowSinging.value = `${singerName} - ${songArtistTitle}`;
      } catch (err) {
        console.error("Error updating document:", err);
      }
    }

    const removeSong = async (requestId, skip) => {
      try {
        if(!showID.value){
          throw error.value = "No showID"
        }
        await store.dispatch('hosts/updateRequest', {requestId, data: {skip: skip}});
      } catch (err) {
        console.error("Error updating document:", err);
      }
    }

    const toggleSingerVisibility = (uniqueKey) => {
      if (typeof singerVisibility.value[uniqueKey] === 'undefined') {
        singerVisibility.value[uniqueKey] = false; // Default to false (hidden) if not previously set
      } else {
        singerVisibility.value[uniqueKey] = !singerVisibility.value[uniqueKey]; // Toggle the current value
      }
    };

    const toggleVisibility = (uniqueKey, listType) => {
      if (!visibility.value[uniqueKey]) {
        visibility.value[uniqueKey] = {};
      }
      visibility.value[uniqueKey][listType] = !visibility.value[uniqueKey][listType];
    };
    
    const calculateTimeDifference = (now, past) => {
      const difference = now.getTime() - past.getTime();
      return Math.floor(difference / 60000); // Convert milliseconds to minutes
    };

    // Inside your Vue component
    const loadKaraokeFiles = async () => {
      if (window.require) {
        const { ipcRenderer } = window.require('electron');
        try {
          const directory = '/path/to/karaoke/files'; // This could come from user input
          const files = await ipcRenderer.invoke('read-karaoke-files', directory);
          console.log('Karaoke files:', files);
          // Process files as needed
        } catch (error) {
          console.error('Error loading karaoke files:', error);
        }
      }
    }

    const copyToClipboard = async (songArtistTitle) => {
      try {
        await navigator.clipboard.writeText(songArtistTitle);
        // You can add more user feedback here (like a tooltip or a small popup)
      } catch (err) {
        console.error("Failed to copy:", err);
      }
    }

    // Initialize visibility to false for all singers
    watch(sortedGroups, (newGroups) => {
      
      if (!Array.isArray(newGroups)) {
        // Handle the case where documents is not an array
        console.warn("Expected 'documents' to be an array, got:", newGroups);
        return [];
      }

      newGroups.forEach(group => {
        if (!visibility.value[group.singerName + '-' + group.singerEmail]) {
          visibility.value[group.singerName + '-' + group.singerEmail] = { unsung: false, sung: false };
        }
      });
    }, { immediate: true });

    const formatTimestamp = async (timestamp) => {
      return formatFirestoreTimestampToTime(timestamp)
    }

    const filterUnsungRequests = (requests) => {
      return requests.filter(request => !request.skip);
    };

    return { 
      user,
      isAuthenticated,
      nowSinging,
      sortedGroups,
      visibility,
      isHost,
      startShow,
      toggleVisibility,
      formatTimestamp,
      processAndSortRequests,
      markAsSung,
      removeSong,
      loadKaraokeFiles,
      copyToClipboard,
      formatFirestoreTimestampToTime,
      filterUnsungRequests,
      toggleSingerVisibility,
      singerVisibility,
      visibleGroups,
      hideSinger,
      toggleHiddenSingers,
      showHiddenSingersText,
      singerButtonText,
      showHiddenSingers,
      toggleHiddenSingersView,
      reinstateSinger,
      getSingerName,
      // hiddenSingersList,
      singersData,
      hiddenSingers,
    };
  }
};
</script>
  
  <style scoped>
    button {
      margin-left: 10px;
      background-color: var(--btnblue1);
    }
    button:hover {
      background-color: var(--btnblue3);
    }
    .sung {
      color:burlywood; /* Or any style to highlight */
    }
    .removed {
      color:darksalmon; /* Or any style to highlight */
    }
    .newSinger {
      color:crimson;
    }
    .zeroRequests {
      color: #777;
    }
    .singNext {
      font-weight: bold;
      color: deeppink;
    }
  </style>
  @/composables/getHostDoc@/composables/docs/getHostDoc@/composables/auth/getUser