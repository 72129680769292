<template>
  <footer id="navFooter" class="navbar">
    <hr>
    <div class="navbar-content">
      <hr>
      <div v-if="isAuthenticated"> 

        <span v-if="isHost">Hosting Enabled: </span>
        <span v-if="isHost && hostName">{{ hostName }} - </span>
        <span v-if="isHost">{{ hostEmail }}.<b>&nbsp;&nbsp;|&nbsp;&nbsp;</b></span>
         
        <span v-if="singerName"><b>Hi </b>{{ singerName }}</span>
        <span v-else><router-link class="makeBtn" to="/addname">Please set your singer name.</router-link></span>.
        
        <span v-if="hostName"><b>Show: </b>{{ hostName }}, </span>
        <span v-if="showName">{{ showName }}</span>
        <span v-if="showVenue"><b> at </b>{{ showVenue }}</span>
        <span v-if="!showID"><b>&nbsp;&nbsp;|&nbsp;&nbsp;</b>No current show</span>.
        <span v-if="isHost && showID"><b>&nbsp;&nbsp;|&nbsp;&nbsp;</b>{{ showID }}</span>
        <div v-if="showTime">{{ showTime }}</div>
        <div id="requestsFooter" v-if="!findShow || findShowExtended">
          <div  v-if="currentRequests.length > 0">

            <div class="fr">Click this to see your new request.</div><br>
            <button class="fr bold" @click="refreshData">
              <span class="icon-item">
                <span class="material-icons" style="color: darkmagenta;">manage_history</span>
              </span>&nbsp;
              <div class="raisedText">Click Here<br>To edit your Requests</div>
            </button>
            <div class="cb"></div>

            <h3 class="fl">Your Requests:</h3>
            <ul class="newReq cb">
              <li v-for="(req, index) in currentRequests" :key="index" :class="{ highlight: req.singNext }">
                <div v-if="!req.skip && !req.guestSinger">
                  {{ req.song }} 
                  <button @click="toggleNext(req)">{{ req.singNext ? 'Not Next' : 'Sing Next' }}</button>
                  <button @click="removeRequest(req)">Remove</button>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>

            <button class="fr bold" @click="refreshData">
              <span class="icon-item">
                <span class="material-icons" style="color: darkmagenta;">manage_history</span>
              </span>&nbsp;
              <div class="raisedText">Show my<br>Requests</div>
            </button>
            <div class="cb"></div>

          </div>
          
          <div v-if="guestRequests.length > 0">
            <h3>Guest Request:</h3>
            <ul class="newReq">
              <li v-for="(req, index) in guestRequests" :key="index" :class="{ highlight: req.singNext }">
                <div v-if="!req.sang && !req.skip">
                  <span v-if="req.guestSinger" class="guestSinger">{{ req.singerName }}:</span>
                  {{ req.song }}
                  <button @click="toggleNext(req)">{{ req.singNext ? 'Not Next' : 'Sing Next' }}</button>
                  <button @click="removeRequest(req)">Remove</button>
                </div>
              </li>
            </ul>
          </div>

          <div v-if="sungRequests.length > 0">
            <h3>Sang:</h3>
            <ul class="sangReq">
              <li v-for="(req, index) in sungRequests" :key="`sang-${index}`">
                <span v-if="req.guestSinger" class="guestSinger">{{ req.singerName }}:</span>
                {{ req.song }} - {{ formatSangAtTime(req.sangAt) }}
              </li>
            </ul>
          </div>
          
          <div v-if="skippedRequests.length > 0">
            <h3>Removed:</h3>
            <ul class="sangReq">
              <li v-for="(req, index) in skippedRequests" :key="index">
                  <span v-if="req.guestSinger" class="guestSinger">{{ req.singerName }}:</span>
                  {{ req.song }}
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div v-else class="unknownUserAlert">
        <p>
          <strong>Unknown user alert:&nbsp;&nbsp;</strong>
          You are not logged in. You may search for songs, find lyrics 
          and practice singing without logging in. Those features can be used anonymously.
          We will, however, need to know who you are in order to create a profile. Having a 
          profile allows you to create song lists (and find them again when you return) and
          make requests at a karaoke show. We allow you to do as much as possible without 
          logging in, but "Request To Sing" and "Save To My List" require a one-time log in. 
          
        </p>
        <p>
          Karaoke List Maker will only ever use your 
          information so the karaoke host can tell you apart from the other singers 
          and so they can call your name when it is your turn. We never sell or share 
          any of your information. If you do not want our weekly emails telling you where 
          you can find karaoke in your area you can check "Decline email updates" when adding or 
          changing your singer name. 
        </p><p>
          Thank you for your participation.
        </p>
      </div>
    </div>
    <br>
    <br>
    <br>
    <div class="gotoTop">
      <span class="noSelect" @click="gotoBottom">Bottom</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span class="noSelect" @click="gotoTop">Top</span>
    </div>
  </footer>
</template>
<script>
// /src/components/NavbarFooter.vue
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'NavbarFooter',
  setup() {
    const store = useStore();
    const isLoading = ref(true);

    const user = computed(() => store.state.auth.user);
    const isAuthenticated = computed(() => !!user.value);

    const showDetails = computed(() => store.getters['shows/showDetails']);
    const showID = computed(() => store.getters['shows/showID']);
    const showVenue = computed(() => showDetails.value?.showVenue ?? 'No venue selected');
    const showCity = computed(() => showDetails.value?.showCity ?? 'No city selected');
    const showName = computed(() => showDetails.value?.showName ?? 'No current show');
    
    const showStartTime = computed(() => showDetails.value?.startsAt ?? '');
    const showEndTime = computed(() => showDetails.value?.endsAt ?? '');
    const showTimeOut = ref(false);
    const singerName = computed(() => store.getters['userDetails/singerName']);
    const userEmail = computed(() => user.value?.email ?? '');
    const isHost = computed(() => store.getters['userDetails/isHost']);
    const hostName = computed(() => store.getters['hosts/hostName']);
    const hostEmail = computed(() => store.getters['hosts/hostEmail']);
    const myReq = computed(() => store.getters['shows/userRequestsForCurrentShow']);

    const findShow = ref(false);
    const findShowExtended = ref(false);

    const showTime = ref(null);

    function gotoTop() {
      window.scrollTo(0, 0);
    }

    function gotoBottom() {
      window.scrollTo(0, 99999);
    }

    const fetchData = async () => {
      if (user.value) {
        isLoading.value = true;
        await Promise.all([
          store.dispatch('userDetails/fetchUserDetails'),
          store.dispatch('shows/fetchCurrentShow'),
          store.dispatch('hosts/fetchHostInfo'),
        ]);

        try {
          showTime.value = formatDate(showDetails);
        } catch (err) {
          console.log('showTime failed:', err.message);
        }

        isLoading.value = false;
      }
    };

    const formatDate = (showDetails) => {
      const now = new Date();
      const showStart = new Date(showDetails.value.startsAt);
      const showEnd = new Date(showStart.getTime() + showDetails.value.duration * 3600000);
      const showExtend = new Date(showStart.getTime() + (showDetails.value.duration + 4) * 3600000);
      const theShowName = showDetails.value?.showName || "The current show";

      let showTime = '';
      const oneWeek = 7 * 24 * 60 * 60 * 1000; // one week in milliseconds

      if (now > showEnd) {
        // Shows do not always end ontime, so let people see requests for another 4 hours.
        if (now > showExtend) {
          findShowExtended.value = false;
        } else {
          findShowExtended.value = true;
        }
        findShow.value = true;
        showTime = 'SHOW ENDED';
      } else if (now >= showStart && now <= showEnd) {
        findShow.value = false;
        findShowExtended.value = false;
        showTime = `${theShowName} ends at ${showEnd.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
      } else if (now.toDateString() === showStart.toDateString()) {
        findShow.value = true;
        findShowExtended.value = false;
        showTime = `Today ${showStart.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}-${showEnd.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
      } else if (showStart - now < oneWeek) {
        findShow.value = true;
        findShowExtended.value = false;
        showTime = `${showStart.toLocaleDateString('en-US', { weekday: 'short' })} ${showStart.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}-${showEnd.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
      } else {
        findShow.value = true;
        findShowExtended.value = false;
        showTime = `${showStart.toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric' })} ${showStart.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true })}`;
      }

      return showTime;
    };

    // Computed properties to split requests into current and sung
    const currentRequests = computed(() => {
      return myReq.value.filter(req => !req.sang && !req.skip && !req.guestSinger);
    });

    const sungRequests = computed(() => {
      return myReq.value.filter(req => req.sang);
    });

    const skippedRequests = computed(() => {
      return myReq.value.filter(req => req.skip);
    });

    const guestRequests = computed(() => {
      return myReq.value.filter(req => req.guestSinger);
    });

    const formatSangAtTime = (sangAt) => {
      if (sangAt && typeof sangAt.toDate === 'function') {
        const date = sangAt.toDate();
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      } else if (sangAt instanceof Date) {
        return sangAt.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      } else {
        const date = new Date(sangAt);
        if (!isNaN(date.getTime())) {
          return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        } else {
          return 'Invalid date';
        }
      }
    };

    // Methods to handle [next] and [remove] actions
    const toggleNext = async (req) => {
      try {
        const requestPath = `KaraokeShows/${showID.value}/Requests/${req.id}`;
        await store.dispatch('shows/updateRequest', {
          path: requestPath,
          data: { singNext: !req.singNext }
        });
        console.log('Toggled next:', req.song);
        await store.dispatch('shows/fetchUserRequestsForCurrentShow');
      } catch (error) {
        console.error('Error toggling request to next:', error);
      }
    };

    const removeRequest = async (req) => {
      try {
        const requestPath = `KaraokeShows/${showID.value}/Requests/${req.id}`;
        await store.dispatch('shows/deleteRequest', {
          path: requestPath,
        });
        req.skip = true;
        console.log('Request removed:', req.song);
        await store.dispatch('shows/fetchUserRequestsForCurrentShow');
      } catch (error) {
        console.error('Error removing request:', error);
      }
    };

    const refreshData = () => {
      try {
        store.dispatch('shows/fetchUserRequestsForCurrentShow')
          .then(() => {
            console.log('Data refreshed successfully');
          })
          .catch(error => {
            console.error('Error refreshing data:', error);
          });
      } catch (error) {
        console.error('Error executing refreshList:', error);
      }
    };

    onMounted(fetchData);
    onMounted(async () => {
      await store.dispatch('shows/fetchCurrentShow');
      await store.dispatch('shows/fetchUserRequestsForCurrentShow');
    });

    watch(user, fetchData);

    watch(myReq, (newMyReq, oldMyReq) => {
      console.log('Requests have been updated from ', oldMyReq, "to", newMyReq);
    }, { deep: true });

    watch(
      () => [store.getters['shows/showID'], store.getters['auth/userEmail']],
      async (newValues, oldValues) => {
        if (newValues.every(val => val)) {
          await store.dispatch('shows/fetchUserRequestsForCurrentShow');
          console.log('newValues, oldValues', newValues, oldValues);
        }
      },
      { immediate: true, deep: true }
    );

    return {
      isAuthenticated,
      isHost,
      hostName,
      hostEmail,
      singerName,
      userEmail,
      showVenue,
      showCity,
      showName,
      showDetails,
      showStartTime,
      showEndTime,
      showTimeOut,
      showTime,
      findShow,
      findShowExtended,
      currentRequests,
      sungRequests,
      skippedRequests,
      guestRequests,
      formatSangAtTime,
      toggleNext,
      removeRequest,
      refreshData,
      gotoTop,
      gotoBottom,
      showID,
    };
  },
};
</script>

<style scoped>
footer.navbar {
  position: relative;
  background-color: var(--twdarkbgd);
  padding: 5px 0;
  margin-bottom: 10px;
  margin-top: 30px;
  width: 100%;
}

li { 
  list-style: none;
}

button {
  padding: 4px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}
button.bold {
  padding: 0px 10px 4px;
  border-radius: 20px;
}
.icon-item {
  vertical-align: bottom;
}

.navbar-container {
  display: flex;
  align-items: left;
  justify-content: space-between;
  width: 100%;
  padding: 5px 0;
}

.navbar-content {
  width: 100%;
}

.navbar-content p {
  margin-top: 5px;
}

.bold {
  font-weight: bold;
  font-size: large;
}

.gotoTop {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.gotoTop span {
  text-decoration: underline;
  cursor: pointer;
  color: var(--twdarkc3);
  font-weight: bold;
  font-size: 2em;
}

.navbar-links {
  list-style: none;
  padding: 0;
}

.navbar-links li {
  display: inline;
  margin-bottom: 15px;
  padding: 15px 5px;
}

.navbar-links a {
  padding: 5px;
  margin: 10px 5px 0 0;
}

.navbar-links a, .navbar-links button {
  padding: 5px;
  margin: 5px;
  font-size: 14px;
}

.newReq li {
  margin-top: 0px;
  padding: 0 0 0 15px;
  color: salmon;
}

.sangReq li {
  margin-top: 0px;
  padding: 0 0 0 15px;
  color: saddlebrown;
}

#requestsFooter h3 {
  margin-bottom: 10px;
}

.guestSinger {
  color: salmon;
}

#requestsFooter button {
  background-color: var(--btnblue1);
  margin: 0 5px 10px 15px;
}

#requestsFooter button:hover {
  background-color: var(--btnblue2);
}

.highlight {
  background-color: var(--myBlue);
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  footer.navbar {
    width: 100%;
  }

  footer.navbar ul {
    padding-inline-start: 0px;
  }

  footer.navbar ul li {
    border: none;
  }

  div.navbar-container {
    width: 100%;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }

  div.navbar-content {
    width: 100%;
  }

  div.navbar-content p {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .navbar-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }

  .navbar-links li {
    padding: 5px;
    margin: 0;
  }

  .navbar-links a, .navbar-links button {
    padding: 5px 5px;
    font-size: 12px;
  }

  .hamburger-menu {
    display: none;
  }
}

.unknownUserAlert p {
  text-align: left;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: linen;
}

.unknownUserAlert p strong {
  font-style: italic;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: antiquewhite;
}

@media (max-width: 480px) {
  .navbar-links a, .navbar-links button {
    font-size: 10px;
    padding: 5px;
  }

  .navbar-links li {
    padding: 3px;
  }
}
</style>
