import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '../firebase/config';
import Login from '../views/auth/Login.vue';
import Signup from '../views/auth/Signup.vue';
import Singin from '../views/auth/SignIn.vue';
import AddName from '../views/auth/AddName.vue';
import FindSongs from '../views/FindSongs.vue';
import Random25 from '../views/Random25.vue';
import FindShows from '../views/FindShows.vue';
import QRShow from '@/views/QRShow.vue';
import NewShow from '../views/hosts/NewShowForm.vue';
import HostShow from '../views/hosts/HostShow.vue';
import Profile from '../views/Profile.vue';
import ShowHistory from '@/views/hosts/ShowHistory.vue';
import ListOptions from '@/components/lists/ListOptions.vue';
import Lists from '@/views/Lists.vue';
import SingSoon from '@/components/lists/SingSoonList.vue';
import Practice from '@/components/lists/PracticeList.vue';
import AllRequests from '@/components/lists/AllRequests.vue';
import VDJ_History from '@/views/VDJ_History.vue';
import EmailList from '@/views/hosts/emailList.vue'

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    next(Login);
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'FindShows',
    component: FindShows,
  },
  {
    path: '/qrid/:qrid',
    name: 'QRShow',
    component: QRShow,
  },
  {
    path: '/emaillist',
    name: 'EmailList',
    component: EmailList,
    beforeEnter: requireAuth,
  },
  {
    path: '/help',
    name: 'HelpPage',
    component: () => import('@/views/HelpPage.vue'),
  },
  {
    path: '/host/showhistory',
    name: 'ShowHistory',
    component: ShowHistory,
    beforeEnter: requireAuth,
  },
  {
    path: '/vdj',
    name: 'VDJ_History',
    component: VDJ_History,
    // beforeEnter: requireAuth,
  },
  {
    path: '/lists',
    name: 'Lists',
    component: Lists,
  },
  {
    path: '/lists/singsoon',
    name: 'SingSoon',
    component: SingSoon,
  },
  {
    path: '/lists/practice',
    name: 'Practice',
    component: Practice,
  },
  {
    path: '/allrequests',
    name: 'AllRequests',
    component: AllRequests,
  },
  {
    path: '/listoptions',
    name: 'ListOptions',
    component: ListOptions,
  },
  {
    path: '/random25',
    name: 'Random25',
    component: Random25,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Singin,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/addname',
    name: 'AddName',
    component: AddName,
  },
  {
    path: '/findshow',
    name: 'FindShows',
    component: FindShows,
  },
  {
    path: '/findsongs',
    name: 'FindSongs',
    component: FindSongs,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/host',
    name: 'HostShow',
    component: HostShow,
    beforeEnter: requireAuth,
  },
  {
    path: '/host/newshow',
    name: 'NewShow',
    component: NewShow,
    beforeEnter: requireAuth,
  },
  {
    path: '/search/:artist',
    name: 'SearchResults',
    component: FindSongs,
    props: true,
  },
  // {
  //   path: '/host/collectkaraokefiles',
  //   name: 'CollectKaraokeFiles',
  //   component: CollectKaraokeFiles,
  //   beforeEnter: requireAuth
  // },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
