// /src/services/showServices.js
import { db } from '@/firebase/config';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';

// Simulated asynchronous function to get the karaoke providers
export async function getKaraokeProvidersAsync() {
    /** 
     * NOTE: This could be a hardcoded map of providers per country => NOT async
     * assuming the courty is already known. Does counrty need to be a param?
     * This info should be cached since it will rarely, if ever change.
     */
    await new Promise(resolve => setTimeout(resolve, 100)); // Simulate delay
    return ['karaoke-version']; // Simulated host email
}

export async function getCurrentShowAsync(identifier) {
  let path;
  if (identifier.includes('@')) {
    path = `Hosts/${identifier}/Shows/currentShow`;
  } else {
    path = `QRID/${identifier}`;
  }

  const docRef = doc(db, path);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return null;
  }

  return docSnap.data();
}

export async function setCurrentShowAsync(hostEmail, showDetails) {
  let QRID = null;
  try {
    const hostDocRef = doc(db, `Hosts/${hostEmail}`);
    const hostDoc = await getDoc(hostDocRef);
    QRID = hostDoc.data().QRID;
    console.log("setCurrentShowAsync QRID:",QRID);
  } catch (error) {
    console.log("Could net get QRID failed!");
    return null; // Handle the case where there is no current show
  }

  try {
    // create the showID and add it to showDetails
    const newShowDocRef = doc(collection(db, `KaraokeShows`))
    const showID = newShowDocRef.id
    const showPath = `KaraokeShows/${showID}/Requests`
    showDetails.showID = showID
    showDetails.showPath = showPath

    const showPathQRID = `QRID/${QRID}`
    const showDocQRIDref = doc(db, showPathQRID)
  
    // create the new showDoc
    await setDoc(newShowDocRef, showDetails)

    // The QR code path is /QRID/{QRID}. 
    // This is so the hostEmail doesn't have to be in the publicly exposed URL
    await setDoc(showDocQRIDref, showDetails)

    // set the hosts .../Shows/currentShow so people can find the showID
    const currentShowDocRef = doc(db, `Hosts/${hostEmail}/Shows/currentShow`);
    await setDoc(currentShowDocRef, showDetails);

    const showArchiveRef = doc(db, `Hosts/${hostEmail}/Shows/${showID}`)
    await setDoc(showArchiveRef, showDetails);

    return showDetails; // Return the show data
  } catch (error) {
    console.log("setCurrentShowAsync failed!");
    return null; // Handle the case where there is no current show
  }
}

export async function showDetailsAllHosts(hosts){
  // Use Promise.all to wait for all show details fetches to complete
  const updatedHosts = await Promise.all(hosts.map(async (host) => {
    const showDetails = await getCurrentShowAsync(host.email);
    if (showDetails) {
      const now = new Date();
      const startsAt = new Date(showDetails.startsAt);
      const endsAt = new Date(showDetails.endsAt);
      // Update isActive status based on the current time and show's active window
      host.isActive = now >= startsAt && now <= endsAt;
      // Attach showDetails directly to the host object
      host.showDetails = showDetails;
    }
    return host;
  }));

  return updatedHosts;
}

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const makeSongId = (song) => {
  let artist, title;
  if(typeof song === 'string') {
    const parts = song.split(" - ")
    artist = parts[0]?.trim()
    title = parts[1]?.trim()
  } else if (song.artist && song.title) {
    artist = song.artist
    title = song.title
  } else {
    console.error("Could not make songID");
    return
  }

  artist = artist?.trim().replace(/\s+/g, "-").replace(/--/g, "-")
  title = title?.trim().replace(/\s+/g, "-").replace(/--/g, "-")
  
  return `${artist}--${title}`.toLowerCase()
}

export const formatTime = (date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0'+minutes : minutes;

  return hours + ':' + minutesStr + ' ' + ampm;
}

export const parseSong = (result) => {
  if (typeof result === "string") {
    const parts = result.split(" - ");
    return { artist: parts[0]?.trim(), title: parts[1]?.trim() };
  }
  return { artist: "", title: "" };
}
