<template>
  <div>
    <JsonSearchComponent :initialSearchTerm="artist" />
    <div class="tip">Less is more. Use partial words.</div>
  </div>
</template>

<script>
// /src/views/FindSong.vue
import JsonSearchComponent from '@/components/JsonSearchComponent.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'FindSongs',
  components: {
    JsonSearchComponent
  },
  setup() {
    const route = useRoute();
    const artist = route.params.artist;

    console.log('FindSongs artist:', artist);

    return { artist };
  }
};
</script>

<style>
/* Add your custom styles here */

</style>
