<template>
  <div class="icon-container">
    <div v-if="!findsongs">
      <router-link to="/findsongs">
        <div class="icon-item">
          <span class="material-icons" style="color: blue;">search</span>
          <p>Find&nbsp;Songs</p>
        </div>
      </router-link>
    </div>
    <!-- <div v-else>
      <div class="disabledFeature">
        <div class="icon-item">
          <span class="material-icons" style="color: blue;">search</span>
          <p>Find&nbsp;Songs</p>
        </div>
      </div>
    </div> -->
    <div v-if="!random25">
      <router-link to="/random25">
        <div class="icon-item">
          <span class="material-icons" style="color: red;">casino</span>
          <p>25&nbsp;Random</p>
        </div>
      </router-link>
    </div>
    <!-- <div v-else>
      <div class="disabledFeature">
        <div class="icon-item">
          <span class="material-icons" style="color: red;">casino</span>
          <p>25&nbsp;Random</p>
        </div>
      </div>
    </div> -->
    <div v-if="isAuthenticated">
      <div v-if="!lists">
        <router-link to="/lists">
          <div class="icon-item">
            <span class="material-icons" style="color: green;">format_list_numbered</span>
            <p>My&nbsp;Lists</p>
          </div>
        </router-link>
      </div>
      <!-- <div v-else>
        <div class="disabledFeature">
          <div class="icon-item">
            <span class="material-icons" style="color: green;">format_list_numbered</span>
            <p>My&nbsp;Lists</p>
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="!help">
      <router-link to="/help">
        <div class="icon-item">
          <span class="material-icons" style="color: #9900CC;">help</span>
          <p>Help!</p>
        </div>
      </router-link>
    </div>
    <!-- <div v-else>
      <div class="disabledFeature">
        <div class="icon-item">
          <span class="material-icons" style="color: #9900CC;">help</span>
          <p>Help!</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'IconSearches',
  setup() {
    const store = useStore();
    const route = useRoute();
    
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);

    const findsongs = computed(() => {
      return route.path === '/findsongs' || route.path.startsWith('/search/');
    });
    const random25 = computed(() => {
      return route.path === '/random25' || route.path.startsWith('/random25/');
    });
    const lists = computed(() => {
      return route.path === '/lists';
    });
    const help = computed(() => {
      return route.path === '/help';
    });

    return {
      isAuthenticated,
      findsongs,
      random25,
      lists,
      help,
    };
  },
};
</script>

<style scoped>
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.icon-container a {
  background-color: transparent;
}

.icon-item {
  text-align: center;
  cursor: pointer;
}

.icon-item .material-icons {
  font-size: 36px;
  border-radius: 50%;
  padding: 5px;
  background-color: #f0f0f0; /* Light grey background */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon-item p {
  margin-top: 5px;
  font-size: 12px;
}

.disabledFeature {
  opacity: 0.4;
}
.disabledFeature .icon-item {
  cursor: default;
  border: 1px solid var(--twdarkc3);
}
a {
  padding: 0;
}
</style>
