import { fetchDocuments } from '@/services/firebaseServices';

export default {
  namespaced: true,
  state: {
    singers: [],
    artists: [],
    artistCount: 0,
    songs: [],
    songCount: 0,
  },
  mutations: {
    SET_SINGERS(state, singers) {
      state.singers = singers;
    },
    SET_ARTISTS(state, artists) {
      state.artists = artists;
    },
    SET_ARTIST_COUNT(state, count) {
      state.artistCount = count;
    },
    SET_SONGS(state, songs) {
      state.songs = songs;
    },
    SET_SONG_COUNT(state, count) {
      state.songCount = count;
    },
    CLEAR_ARTISTS(state) {
      state.artists = [];
    },
    CLEAR_SONGS(state) {
      state.songs = [];
    }
  },
  actions: {
    async fetchSingers({ commit }) {
      try {
        const singers = await fetchDocuments('VDJ_Karaoke');
        const uniqueSingers = [...new Set(singers.map(song => song.singerName))];
        commit('SET_SINGERS', uniqueSingers);
      } catch (error) {
        console.error('Error fetching singers:', error);
      }
    },
    async fetchArtists({ commit }, singer) {
      try {
        const songs = await fetchDocuments('VDJ_Karaoke', [['singerName', '==', singer]]);
        const uniqueArtists = [...new Set(songs.map(song => song.artist))];
        commit('SET_ARTISTS', uniqueArtists);
        commit('SET_ARTIST_COUNT', uniqueArtists.length)
        commit('SET_SONG_COUNT', songs.length)
      } catch (error) {
        console.error('Error fetching artists:', error);
      }
    },
    async fetchSongs({ commit }, { singer, artist }) {
      try {
        const songs = await fetchDocuments('VDJ_Karaoke', [['singerName', '==', singer], ['artist', '==', artist]]);
        commit('SET_SONGS', songs);
      } catch (error) {
        console.error('Error fetching songs:', error);
      }
    },
    clearArtists({ commit }) {
      commit('CLEAR_ARTISTS');
    },
    clearSongs({ commit }) {
      commit('CLEAR_SONGS');
    }
  },
  getters: {
    singers: state => state.singers,
    artists: state => state.artists,
    artistCount: state => state.artistCount,
    songs: state => state.songs,
    songCount: state => state.songCount,
  }
};