// /src/store/modules/auth.js
import { auth } from '@/firebase/config';
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { serverTimestamp } from 'firebase/firestore';

export default {
  namespaced: true,
  state: {
    user: null,
    authIsReady: false,
    error: null,
    isLoginModalVisible: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      console.log('user state changed:',state.user);
    },
    SET_AUTH_IS_READY(state, isReady) {
      state.authIsReady = isReady;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SHOW_LOGIN_MODAL(state) {
      state.isLoginModalVisible = true;
    },
    HIDE_LOGIN_MODAL(state) {
      state.isLoginModalVisible = false;
    },
  },
  actions: {
    authIsReady(state) {
      return state.authIsReady;
    },
    async signup({ commit, dispatch }, { email, password, onSuccess }) {
      try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        commit('SET_USER', res.user);
        // Now that the user is signed up and logged in, create or update the user details in Firestore
        await dispatch('userDetails/createUserDetails', { 
          email: res.user.email,
          userDetails: {
            email: res.user.email,
            // Add any initial user details here. For example:
            emailList: true, // Assuming you want new users to be subscribed by default
            // Any other initial user details...
            timestamp: serverTimestamp(),

          }
        }, { root: true }); // { root: true } is used to dispatch an action in another namespaced module
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(res.user);
        }
      } catch (error) {
        console.error('Signup failed:', error.message);
        commit('SET_ERROR', error.message)
        throw error; // Rethrow to handle in the component if necessary
      }
    },
    async login({ commit }, { email, password, onSuccess }) {
      try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        commit('SET_USER', res.user);
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(res.user);
        }
      } catch (error) {
        console.error('Login failed:', error.message);
        commit('SET_ERROR', error.message)
        throw error;
      }
    },
    async signInWithGoogle({ commit, dispatch }, payload = {}) {
      try {
        const provider = new GoogleAuthProvider();
        const res = await signInWithPopup(auth, provider);
        commit('SET_USER', res.user);
        await dispatch('userDetails/createUserDetails', { 
          email: res.user.email,
          userDetails: {
            email: res.user.email,
            // Add any initial user details here. For example:
            emailList: true, // Assuming you want new users to be subscribed by default
            // Any other initial user details...
            timestamp: serverTimestamp(),

          }
        }, { root: true });
        // Check if onSuccess callback is provided and is a function
        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
          payload.onSuccess(res.user);
        }
      } catch (error) {
        console.error('Google sign-in failed:', error.message);
        commit('SET_ERROR', error.message);
        throw error;
      }
    },
    async updateProfile({ commit, dispatch }, { displayName }) {
      try {
        await auth.currentUser.updateProfile({ displayName });
        commit('SET_USER', auth.currentUser);
        // Now update the user details in Firestore
        await dispatch('userDetails/updateUserDetails', {
          email: auth.currentUser.email,
          userDetails: { singerName: displayName }
        }, { root: true });
      } catch (error) {
        console.error('Error updating profile:', error.message);
      }
    },
    async doLogout({ commit, dispatch }) {
      try {
        await dispatch('show/endShow', null, { root: true });
        await signOut(auth);
        commit('SET_USER', null);
      } catch (error) {
        console.error('Logout failed:', error.message);
      }
    },
    initializeAuthState({ commit }) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        commit('SET_USER', user);
        commit('SET_AUTH_IS_READY', true);
        unsubscribe();
      });
    },
    async resetPassword({ commit }, email) {
      try {
        // Use your authentication service to send a password reset email
        await sendPasswordResetEmail(auth, email);
        console.log('Password reset email sent successfully.');
        // Handle success, possibly setting a state variable or logging
      } catch (error) {
        console.error('Error sending password reset email:', error);
        commit('SET_ERROR', error.message)
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    authIsReady(state) {
      return state.authIsReady;
    },
    getUser(state) {
      return state.user
    },
    userEmail: state => state.user?.email,
  }
}