// src/store/modules/notifications.js

export default {
  namespaced: true,
  state: {
    messages: [],
  },
  mutations: {
    ADD_MESSAGE(state, payload) {
      state.messages.push(payload);
    },
    REMOVE_MESSAGE(state, messageIndex) {
      state.messages.splice(messageIndex, 1);
    },
  },
  actions: {
    addMessage(state, { commit }, payload) {
      commit('ADD_MESSAGE', payload);
      // Optionally, remove the message after a delay
      if (payload.timeout) {
        setTimeout(() => {
          commit('REMOVE_MESSAGE', state.messages.length - 1);
        }, payload.timeout);
      }
    },
  },
  getters: {
    getMessages(state) {
      return state.messages;
    },
  },
};
