// src/store/modules/userDetails.js
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase/config';
import { fetchDocument, saveDocument } from '@/services/firebaseServices';
import { serverTimestamp } from 'firebase/firestore';

export default {
  namespaced: true,
  state: {
    userDetails: null,
    error: null,
  },
  mutations: {
    SET_USER_DETAILS(state, details) {
      state.userDetails = details;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    }
  },
  actions: {
    async fetchUserDetails({ commit, rootState }) {
      const user = rootState.auth.user;
      const email = rootState.auth.user?.email;
      if (user && email) {
        try {
          const userDetails = await fetchDocument(`Singers/${email}`);
          commit('SET_USER_DETAILS', userDetails);
          commit('CLEAR_ERROR');
        } catch (error) {
          console.error('Error fetching user details:', error.message);
          commit('SET_ERROR', error.message);
        }
      } else {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            try {
              const userDetails = await fetchDocument(`Singers/${user.email}`);
              commit('SET_USER_DETAILS', userDetails);
              commit('CLEAR_ERROR');
            } catch (error) {
              console.error('Error fetching user details:', error.message);
              commit('SET_ERROR', error.message);
            }
          }
        });
      }
    },
    async createUserDetails({ commit }, { email, userDetails }) {
      try {
        // Using the saveDocument service function
        await saveDocument(`Singers/${email}`, {
          email: userDetails?.email || email,
          timestamp: serverTimestamp(),
          emailList: userDetails?.emailList || true,
          singerName: userDetails?.singerName || "",
          isHost: userDetails?.isHost || false
        });

        commit('SET_USER_DETAILS', userDetails);
        commit('CLEAR_ERROR');
      } catch (error) {
        console.error('Error creating user details:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    async updateUserDetails({ commit }, { email, userDetails }) {
      console.log('userDetails.js says email:',email);
      console.log('userDetails.js says userDetails:',userDetails);
      try {
        await saveDocument(`Singers/${email}`, userDetails, { merge: true });
        commit('SET_USER_DETAILS', userDetails);
        commit('CLEAR_ERROR');
        // Log to confirm update was successful
        console.log('User details updated:', userDetails);
      } catch (error) {
        console.error('Error updating user details:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
  },
  getters: {
    isHost(state) {
      return !!state.userDetails && state.userDetails.isHost;
    },
    userEmail(state, getters, rootState) { 
      //TODO: this is confusing because the user(Auth)Email is not always the user(Details)Email
      return rootState.auth.user?.email || false;
    },
    singerName(state) {
      return state.userDetails?.singerName || "";
    },
    userDetails(state) {
      return state.userDetails;
    }
  }
};
