<template>
  <div>
    <!-- <h1>My Karaoke Lists</h1> -->
    <IconLists @listSelected="handleListSelection"/>
 
    <!-- Dynamic component based on the selected list or conditional rendering -->
    <div v-if="currentListType">
      <component :is="currentListComponent" :songs="songs"/>
    </div>
  </div>
</template>

<script>
// /src/views/Lists.vue
import IconLists from '@/components/IconsLists.vue';

// Import components for each list type
import AllList from '@/components/lists/AllList.vue'
import ArtistList from '@/components/lists/ArtistList.vue'
import CustomList from '@/components/lists/CustomList.vue'
import DuetsList from '@/components/lists/DuetsList.vue'
import FavoritesList from '@/components/lists/FavoritesList.vue';
import GenreList from '@/components/lists/GenreList.vue';
import LanguageList from '@/components/lists/LanguageList.vue';
import PracticeList from '@/components/lists/PracticeList.vue'
import RateList from '@/components/lists/RateList.vue'
import RequestsList from '@/components/lists/RequestsList.vue'
import SangList from '@/components/lists/SangList.vue'
import SingList from '@/components/lists/SingList.vue'
import SingSoonList from '@/components/lists/SingSoonList.vue'
import UnsungList from '@/components/lists/UnsungList.vue'
// etc...

export default {
  components: {
    IconLists,
    AllList,
    ArtistList,
    CustomList,
    DuetsList,
    FavoritesList,
    GenreList,
    LanguageList,
    PracticeList,
    RateList,
    RequestsList,
    SangList,
    SingList,
    SingSoonList,
    UnsungList,
    // etc...
  },
  data() {
    return {
      currentListType: null,
      songs: [], // Populate based on selected list
    };
  },
  computed: {
    currentListComponent() {
      switch (this.currentListType) {
        case 'all':
          return 'AllList';
        case 'artists':
          return 'ArtistList';
        case 'custom':
          return 'CustomList';
        case 'duets':
          return 'DuetsList';
        case 'favorites':
          return 'FavoritesList';
        case 'genre':
          return 'GenreList';
        case 'language':
          return 'LanguageList';
        case 'practice':
          return 'PracticeList';
        case 'rate':
          return 'RateList';
        case 'requests':
          return 'RequestsList';
        case 'sang':
          return 'SangList';
        case 'sing':
          return 'SingList';
        case 'singsoon':
          return 'SingSoonList';
        case 'unsung':
          return 'UnsungList';
        // add more cases as needed
        default:
          return null;
      }
    },
  },
  methods: {
    handleListSelection(listType) {
      this.currentListType = listType;
      // Fetch and set songs for the selected list type
    },
  },
};
</script>


