<template>
  <div class="">
    <div class="littleBtn" @click="togglePracticeOptions">
      <span class="material-icons" style="color: greenyellow;">mic_external_on</span>
      <div class="raisedText">Practice</div>
    </div>
    <div v-if="showPracticeOptions">
      <div @click="youTubeKaraoke(result)" class="makeBtn littleBtn">YouTube Karaoke</div>
      <div @click="youTubeLyrics(result)" class="makeBtn littleBtn">YouTube Lyrics</div>
      <div @click="lyrics(result)" class="makeBtn littleBtn">Song Lyrics</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Practice',
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPracticeOptions: false,
    };
  },
  methods: {
    togglePracticeOptions() {
      this.showPracticeOptions = !this.showPracticeOptions;
    },
    youTubeKaraoke(result) {
      let urlSrt = 'https://www.youtube.com/results?search_query=';
      urlSrt += result.replace(/[-&_,]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ').join('+');
      urlSrt += '+karaoke';
      window.open(urlSrt, '_blank');
    },
    youTubeLyrics(result) {
      let urlSrt = 'https://www.youtube.com/results?search_query=';
      urlSrt += result.replace(/[-&_,]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ').join('+');
      urlSrt += '+lyrics';
      window.open(urlSrt, '_blank');
    },
    lyrics(result) {
      let urlSrt = 'https://www.google.com/search?q=lyrics+';
      urlSrt += result.replace(/[-&_,]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ').join('+');
      window.open(urlSrt, '_blank');
    },
  },
};
</script>

<style scoped>
.raisedText {
  margin-left: 20px;
  color: burlywood;
  text-decoration: none;
}
</style>
