<template>
  <div id="addName">
    <div v-if="updatedSingerName">
      <div class="updated" v-if="updatedSingerName">
        Your new singer name is: {{ updatedSingerName }}.
      </div>
      <div class="updated" v-if="updatedSingerEmail">Your new singer name is: {{ updatedSingerEmail }}.</div>
    </div>
    <div v-else>
      <form @submit.prevent="handleSubmit">
        <h3>Add Singer Name</h3>

        <label name="singerName" v-if="currentSingerName">
          Your current singer name is: {{ currentSingerName }}
          <div class="doNot">(Please DO NOT change your name during a show.)</div>
        </label>
        <input type="text" id="singerName" name="singerName" placeholder="Singer Name (required)" v-model="newSingerName" required>
        <label name="newUserEmail" v-if="currentEmail">Your current email address is: {{ currentEmail }}</label>
        <label name="newUserEmail" v-else-if="userEmail">Your current email address is: {{ userEmail }}</label>
        <input type="email" name="newUserEmail" placeholder="Update Email (optional)" v-model="newUserEmail">
        <div v-if="error" class="error">{{ error }}</div>
        <label>Decline email updates: </label><input type="checkbox" name="noEmail" id="noEmail" v-model="noEmail">

        <button v-if="!isPending">Update Name</button>
        <button v-if="isPending" disabled>Loading...</button>
      </form>
    </div>
  </div>
</template>

<script>
// /src/views/auth/AddName.vue
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

// TODO: consider disabling this feature while someone is at a show. 
//       Alternativly remove all request under current name, thereby moving them to the end of the list:)
export default {
  setup(props, { emit }) {
    const store = useStore();
    const error = ref('');
    const isPending = ref(false);
    const user = computed(() => store.getters['auth/getUser']);
    const userEmail = ref(user.value?.email) 
    const currentEmail = ref(null)
    const userDetails = computed(() => store.getters['userDetails/userDetails']);
    const currentSingerName = computed(() => store.getters['userDetails/singerName']);
    const newSingerName = ref('');
    const newUserEmail = ref(null)
    const noEmail = ref(false)
    const showDetails = computed(() => store.getters['shows/showDetails'])
    const userObj = ref({})
    const updatedSingerName = ref('')
    const updatedSingerEmail = ref('')

    // Watch for changes in the user and update userEmail accordingly
    watch(user, (newValue, oldValue) => {
      userEmail.value = newValue?.email;
      console.log('userEmail oldValue, newValue:',oldValue, newValue);
    }, { immediate: true }); // { immediate: true } ensures this runs immediately on setup

    // setTimeout(() => {
    //   document.getElementById("singerName").focus();
    // }, 500)
    onMounted(() => {
      document.getElementById("singerName").focus();
    })

    const handleSubmit = async () => {
      if (!newSingerName.value) {
        error.value = "Please enter a singer name.";
        return;
      }
      isPending.value = true;

      // Initialize userObj.value with a default structure
      userObj.value.singerName = newSingerName.value;
      userObj.value.emailList = !noEmail.value;

      // Determine the correct email value
      const emailToUse = newUserEmail.value || userDetails.value?.email || userEmail.value;
      if (emailToUse) {
        userObj.value.email = emailToUse.toLowerCase();
        currentEmail.value = emailToUse.toLowerCase();
      } else {
        console.error("Email is not available.");
        return; // Exit if we don't have an email to use
      }

      // Add additional properties as needed...
      if (showDetails.value?.hostEmail) {
        userObj.value.currentHost = showDetails.value.hostEmail;
      }

      try {
        await store.dispatch('userDetails/updateUserDetails', {email: userEmail.value, userDetails:userObj.value});
        isPending.value = false;
        error.value = '';
        updatedSingerName.value = newSingerName.value;
        emit('singerNameSet', newSingerName.value);
        if(newUserEmail.value){
          updatedSingerEmail.value = newUserEmail.value;
        }
        newSingerName.value = ''; // Clear input after successful update
        newUserEmail.value = '';
      } catch (err) {
        isPending.value = false;
        error.value = err.message;
      }
    };

    return {
      user,
      currentSingerName,
      newSingerName,
      updatedSingerName,
      updatedSingerEmail,
      userDetails,
      userEmail,
      currentEmail,
      newUserEmail,
      noEmail,
      showDetails,
      handleSubmit,
      error,
      isPending
    };
  }
};
</script>


<style scoped>
  #addName h3 { color: var(--twdarkbgm);}
  #addName input { border: solid 1px var(--twdarkbgl)}
  #addName label, #addName div {
    color: black;
  }
  #noEmail { 
    display: inline; 
    width: 40px;
  }
  input, textarea {
    border: 0;
    border-bottom: 1px solid var(--twdarkc3);
    padding: 10px;
    outline: none;
    display: block;
    width: 100%;
    margin: 20px auto;
  }
  #addName div .updated {
    margin-top: 10px;
    font-weight: bold;
    color: var(--twdarkc1);
  }
  #addName label div.doNot {
    color: var(--warning);
    font-size: small;
  }
</style>