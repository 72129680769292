<template>
  <div>
    <h1>Songs by Custom Lists</h1>
    <!-- Iterate over songs grouped by custom lists -->
    <div v-for="(group, listName) in groupedSongsByCustomList" :key="listName">
      <!-- Clicking on list name toggles the visibility of its songs -->
      <h2 @click="toggleGroup(listName)">{{ listName }}</h2>
      <ul v-if="isVisibleGroup(listName)">
        <li v-for="song in group" :key="song.songID" @click.stop="toggleSubList(song.songID)">
          {{ song.artist }} - {{ song.title }}
          <!-- Sublist toggle button -->
          <!-- <button @click.stop="toggleSubList(song.songID)">Toggle Details</button> -->
          <!-- Sublist component -->
          <div v-if="isVisible(song.songID)">
            <SubList :result="song.song" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// /src/components/lists/CustomList.vue
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import SubList from '@/components/SubList.vue';

export default {
  components: { SubList },
  setup() {
    const store = useStore();
    const songs = computed(() => store.getters['lists/getUserKaraokeList']);
    const visibleSubLists = reactive(new Set());
    const visibleGroups = reactive(new Set());

    onMounted(async () => {
      await store.dispatch('lists/fetchUserKaraokeList');
    });

    const groupedSongsByCustomList = computed(() => {
      return groupSongsByCustomList(songs.value);
    });

    function toggleSubList(songID) {
      if (visibleSubLists.has(songID)) {
        visibleSubLists.delete(songID);
      } else {
        visibleSubLists.add(songID);
      }
    }

    function isVisible(songID) {
      return visibleSubLists.has(songID);
    }

    function toggleGroup(listName) {
      if (visibleGroups.has(listName)) {
        visibleGroups.delete(listName);
      } else {
        visibleGroups.add(listName);
      }
    }

    function isVisibleGroup(listName) {
      return visibleGroups.has(listName);
    }

    return { 
      songs, 
      groupedSongsByCustomList,
      toggleSubList,
      isVisible,
      toggleGroup,
      isVisibleGroup,
    };
  },
};

// Utility function to group songs by custom lists
function groupSongsByCustomList(songs) {
  const groups = {};
  
  songs.forEach(song => {
    // Ensure there's an array to iterate over, even if it's empty
    const lists = song.myLists || [];
    lists.forEach(listName => {
      if (!groups[listName]) {
        groups[listName] = [];
      }
      groups[listName].push(song);
    });
  });

  return groups;
}
</script>
