<template>
  <h1>Mailing List Yes</h1>
  <ol>
    <div v-for="(singer, id) in allSingers" :key="id">
      <li v-if="singer.emailList">
        {{ singer.singerName }} &lt;{{ singer.email }}&gt;
      </li>
    </div>
  </ol>

  <h1>Mailing List No</h1>
  <ol>
    <div v-for="(singer, id) in allSingers" :key="id">
      <li v-if="!singer.emailList">
        {{ singer.singerName }} &lt;{{ singer.email }}&gt;
      </li>
    </div>
  </ol>

</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { db } from '@/firebase/config';
import { getDocs, collection } from 'firebase/firestore';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const allSingers = ref([])
    const user = computed(() => store.state.auth.user);
    const userEmail = computed(() => user.value?.email);
    const tempRestraint = 'hire@kjpaul.com';

    // Fetch singers from Firestore
    const fetchSingers = async () => {
      console.log("1. userEmail.value, tempRestraint",userEmail.value, tempRestraint);
      
      /** For now to make sure only I can use this feature. */
      if(userEmail.value !== tempRestraint) { 
        setTimeout(() => {
          if(userEmail.value !== tempRestraint){
            window.location.href = "/findsongs";
          }
        }, 1000)

        return
      }

      const querySnapshot = await getDocs(collection(db, 'Singers'));
      allSingers.value = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    };

    onMounted(fetchSingers)

    return {
      allSingers,
    }
  }
}
</script>

<style>

</style>