<template>
  <div @click.stop>
    <div @click="handleRequestClick" v-if="!submitRequestClicked && !error">
      <div class="makeBtn">
        <span v-if="atShow">Request To Sing</span>    
        <span v-else>Find Show & Send Request</span>
      </div>
    </div>
    <div v-else>
      <!-- <div v-if="error" class="error">{{ error }}</div> -->

      <!-- Handle user login/signup -->
      <div v-if="!isAuthenticated">
        Unknown User. 
        <a href='#' @click="toggleLogin">Log-in</a> or
        <a href='#' @click="toggleSignup">Signup</a> or use 
        <a href='#' @click="signInWithGoogle">Google Auth</a>
        to use this function.

        <!-- Login and Signup forms -->
        <Login v-if="showLogin" @loginSuccess="handleLoginSuccess" />
        <Signup v-if="showSignup" @signupSuccess="handleSignupSuccess" />

      </div>
      <div v-else>
        <div v-if="!singerName || singerName == ''">
          <!-- Handle adding display name -->
          <AddName @singerNameSet="handleAddNameSuccess" />
        </div>
        <div v-else>
          <div v-if="!atShow">
            <FindShows />
          </div>
            <div v-else>
            <!-- Display request message or error -->
            <div class="clickMsg" v-if="clickMsg">{{ clickMsg }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// /src/components/RequestToSing.vue
import { computed, ref, onMounted, watchEffect, watch } from 'vue';
import { useStore } from 'vuex';
import Login from '@/views/auth/Login.vue';
import Signup from '@/views/auth/Signup.vue';
import AddName from '@/views/auth/AddName.vue';
import FindShows from '@/views/FindShows.vue';

export default {
  name: 'RequestToSing',
  props: ['result'],
  components: {
    Login,
    Signup,
    AddName,
    FindShows,
  },

    setup(props, { emit }) {
    const store = useStore();

    // State references
    const submitRequestClicked = ref(false);
    const error = ref('');
    const clickMsg = ref('');
    const showLogin = ref(false);
    const showSignup = ref(false);
    const justLoggedIn = ref(false);

    // Computed properties to pull state and getters from Vuex
    const user = computed(() => store.state.auth.user);
    const isAuthenticated = computed(() => !!user.value);
    const showDetails = computed(() => store.getters['shows/showDetails']);
    const singerName = computed(() => store.getters['userDetails/singerName']);
    const updatedSingerName = ref(null);
    const requestsPaused = computed(() => store.getters['shows/requestsPaused']);
    const atShow = computed(() => store.getters['shows/atShow']);
    const isActive = computed(() => store.getters['shows/showIsActive']);
    const currentHost = computed(() => store.getters['hosts/getCurrentHost']);
    const requestStatus = ref('');

    const sendRequest = async () => {
      if (!isAuthenticated.value || !props.song) {
        emit('error', 'Authentication or song data missing.');
        return;
      }
      try {
        await store.dispatch('submitSongRequest', props.song);
        emit('success', 'Request submitted successfully.');
      } catch (error) {
        emit('error', 'Failed to submit request.');
      }
      try {
        store.dispatch('shows/fetchUserRequestsForCurrentShow')
          .then(() => {
            console.log('Data refreshed successfully');
          })
          .catch(error => {
            console.error('Error refreshing data:', error);
          });
      } catch (error) {
        console.error('Error executing refreshList:', error);
      }
    };

    const fetchCurrentShowDetails = async () => {
      if (!currentHost.value || !currentHost.value.hostEmail) {
        console.error("currentHost is undefined:");
        return;
      }
      
      if (currentHost.value?.hostEmail) {
        try {
          // This assumes you have a Vuex action that sets the current show details globally
          await store.dispatch('shows/setCurrentShow', currentHost.value);
          requestStatus.value = `Connected to show at ${currentHost.value?.venue} hosted by ${currentHost.value?.hostName}.`;
          // Maybe navigate to a new route or update a global state that triggers RequestToSing to appear
        } catch (error) {
          console.error("Failed to set current show:", error);
          requestStatus.value = "Failed to connect to show.";
        }
      } else {
        requestStatus.value = "Invalid currentHost details provided.";
      }
    };

    const handleRequestClick = async () => {
      console.log('atShow and isActive', atShow.value, isActive.value);

      if (requestsPaused.value) {
        clickMsg.value = "Requests are paused. No new requests at this time.";
        console.log("Requests are paused!");
        return;
      }
      submitRequestClicked.value = true;

      if (!isAuthenticated.value || !singerName.value ) {
        justLoggedIn.value = true;
        clickMsg.value = "Waiting on Singer Name...";
        return;
      } 

      if ( isAuthenticated.value && singerName.value){
        clickMsg.value = "Sending request...";
        await requestToSing();
      }
    };

    // Effect to automatically fetch the current show details when component is mounted
    // TODO: I think this means there is a request for each song listed... probably to the idea... move inside handle click!
    onMounted(async () => {
      await fetchCurrentShowDetails();
    });

    // Watchers, methods for toggling forms, and handling sign in with Google could be added here...
    const toggleLogin = () => {
      showLogin.value = !showLogin.value;
      showSignup.value = false;
    };
    const toggleSignup = () => {
      showSignup.value = !showSignup.value;
      showLogin.value = false;
    };

    // Method to handle Google Sign-In
    const signInWithGoogle = async () => {
      try {
        // Dispatch the signInWithGoogle action from Vuex auth module
        await store.dispatch('auth/signInWithGoogle');
        if(singerName.value){
          handleRequestClick();
        } 
        
        console.log('Google Sign-In successful.');
        clickMsg.value = "Login success. Sending request...";
      } catch (error) {
        if(isAuthenticated.value){
          // handleRequestClick();
          console.log("Google Auth showing error...");
          return;
        }
        clickMsg.value = "Google Sign-In failed, please try again.";
        console.error('Google Sign-In failed:', error.message);
        // Handle sign-in error, e.g., by showing an error message to the user
      }
    };

    const handleLoginSuccess = async () => {
      showLogin.value = false;
      // After signup, check if the user has a singerName
      if(user?.value){
        clickMsg.value = "Login success...";
        handleRequestClick();
      }
    };

    const handleSignupSuccess = async () => {
      showSignup.value = false;
      if(user?.value){
        clickMsg.value = "New user created...";
        handleRequestClick();
      }
    };

    const handleAddNameSuccess = async () => {
      // Assuming you have a way to wait for the AddName to finish 
      // (e.g., via a promise that resolves on the singerNameSet event)
      await new Promise((resolve) => {
        const offEvent = watch(updatedSingerName, (newName) => {
          if (newName) {
            offEvent(); // Stop watching
            resolve();
          }
        });
      });

      clickMsg.value = "New singer name saved...";
      await handleRequestClick();
      console.log('singerName.value:',singerName.value);
    };

    watchEffect(() => {
      if (user?.value && justLoggedIn?.value && singerName.value) {
        console.log('watchEffect sending handleRequest Click');
        justLoggedIn.value = false;
        handleRequestClick();
      }
    });

    const requestToSing = async () => {
      if (!isAuthenticated.value) {
        clickMsg.value = "Unknown user, please log in...";
        error.value = "You must be logged in and a song must be selected.";
        return;
      }

      clickMsg.value = "Sending request...";

      const hostName = showDetails.value?.hostName || currentHost.value?.hostName || 'Unknown Host';
      if(!props.result) {
        clickMsg.value = `Request failed. Please try again or talk to ${hostName}.`;
        console.error(`Request failed. No song data available. No props.result: ${props?.result}.`);
        error.value = "Request failed. No song data available.";
        return;
      }

      if (requestsPaused.value) {
        clickMsg.value = `Requests are paused. No requests allowed at this time.`;
        return;
      }

      // if (!showDetails.value) {
      //   clickMsg.value = `No requests allowed at this time. Please talk to ${hostName}.`
      //   return;
      // }

      try {
        await store.dispatch('shows/submitSongRequest', { song:props.result, singerName: singerName.value });
        clickMsg.value = `Request successfully sent to ${hostName}. Press "Refresh List" in footer to see requests.`;
        
        error.value = '';
      } catch (err) {

        const testShowID = store.getters['shows/showID'];
        if(!testShowID){
          // clickMsg.value = `Please talk to ${hostName} to verify that request was delivered.`;
          clickMsg.value = `Thanks for your request. Click "Refresh List" in footer to see request status.`;
          error.value = err.message;
        } else {
          clickMsg.value = `Thanks for your request. Click "Refresh List" in footer to see request status.`;
        }
      } 
    };

    return {
      // Return the properties and methods
      user,
      isAuthenticated,
      singerName,
      error,
      clickMsg,
      submitRequestClicked,
      handleRequestClick,
      showLogin,
      showSignup,
      toggleLogin,
      toggleSignup,
      signInWithGoogle,
      handleLoginSuccess,
      handleSignupSuccess,
      handleAddNameSuccess,
      requestToSing,
      sendRequest,
      atShow,
    };
  },
};
</script>


<style scoped>
  .clickMsg {
    color: var(--myYellow);
  }
</style>
