<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <h3>Sign up</h3>

      
      <p>Please enter a singer name:</p>
      <p><input type="text" id="newSingerName" name="newSingerName" placeholder="Singer Name (Required)" v-model="newSingerName" required></p>
      
      <p><input type="email" name="email" placeholder="Email" id="signupFormEmail" v-model="email" required></p>
      <p><input type="password" name="password" placeholder="Password" v-model="password" required></p>

      <p>
        <button v-if="!isPending">Sign up</button>
        <button v-if="isPending" disabled>Loading...</button>
      </p>
      <div v-if="error" class="error">{{ error }}</div>

    </form>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const error = ref(null);
    const isPending = ref(false);
    const singerName = computed(() => store.getters['userDetails/singerName'])
    const newSingerName = ref(null)


    const store = useStore();
    // const router = useRouter();

    setTimeout(() => {
      document.getElementById("newSingerName").focus();
    }, 500);

    const handleSubmit = async () => {
      isPending.value = true;
      error.value = null; // Reset previous errors
      try {
        const emailLowercase = email.value.toLowerCase();
        await store.dispatch('auth/signup', {
          email: emailLowercase,
          password: password.value
        });

        // Check if there's a new singer name provided
        if (newSingerName.value) {
          await store.dispatch('userDetails/updateUserDetails', {
            email: emailLowercase,
            userDetails: {
              singerName: newSingerName.value
            }
          });
          console.log('New singer name set:', newSingerName.value);
        }

        isPending.value = false;
        // Emit an event to the parent component to indicate successful signup
        // You can use this to close the signup modal or redirect the user
        // router.push('/dashboard'); // Redirect the user to a new page after signup
      } catch (err) {
        isPending.value = false;
        console.error('Signup failed:', err.message);
        error.value = err.message;
      }
    };

    return {
      email,
      password,
      singerName,
      newSingerName,
      handleSubmit,
      error,
      isPending
    };
  }
};
</script>

<style scoped>
  h3, p { color: var(--twdarkbgm);}
  p { 
    margin-bottom: 5px;
  }
  input { 
    border: solid 1px var(--twdarkbgl);
    width: 80%;
  }

</style>