<template>
  <div>
    <div v-if="showDetails">
      <h3>You have joined: {{ showDetails.showName }} at {{ showDetails.showVenue }}</h3>
      <div v-if="isActive">
        <p>Show is currently active.</p>
      </div>
      <div v-else-if="willStartSoon">
        <p>The new show starts in {{ timeUntilStart }} minutes.</p>
      </div>
      <div v-else>
        <p>Show Ended</p>
      </div>
      <button v-if="!displayDetails" class="makeBtn" @click="displayDetails=true">See Show Details</button>
      <button v-if="displayDetails" class="makeBtn" @click="displayDetails=false">Hide Details</button>
      <div v-if="displayDetails">
        <p><strong>Host:</strong> {{ showDetails.hostName }}</p>
        <p><strong>City:</strong> {{ showDetails.showCity }}</p>
        <p><strong>Starts At:</strong> {{ formatTimestamp(showDetails.startTS) }}</p>
        <p><strong>Ends At:</strong> {{ formatTimestamp(showDetails.endTS) }}</p>
        <p v-if="showDetails.requestsPaused"><strong>Requests are paused currently.</strong></p>
      </div>
      <FindSongs :showDetails="showDetails" v-if="isActive || willStartSoon" />
    </div>
    <div v-else>
      <p>No active show found for this QRID.</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import FindSongs from './FindSongs.vue';

export default {
  components: {
    FindSongs
  },
  setup() {
    const store = useStore();
    const showDetails = ref(null);
    const displayDetails = ref(false);
    const isActive = ref(false);
    const willStartSoon = ref(false);
    const timeUntilStart = ref(null);

    const fetchShowByQRID = async (qrid) => {
      await store.dispatch('shows/fetchShowByQRID', qrid);
      const details = store.getters['shows/showDetails'];
      showDetails.value = details;

      if (details) {
        const now = new Date();
        const startTime = details.startTS.toDate();
        const endTime = details.endTS.toDate();

        isActive.value = now >= startTime && now <= endTime;
        
        if (now < startTime) {
          const timeDifference = (startTime - now) / 60000; // Time in minutes
          willStartSoon.value = timeDifference <= 120; // Check if the show starts within the next 2 hours
          timeUntilStart.value = Math.round(timeDifference);
        }
      }
    };

    const formatTimestamp = (timestamp) => {
      if (!timestamp || !timestamp.seconds) return 'Invalid date';
      const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
      return date.toLocaleString();
    };

    onMounted(() => {
      const qrid = window.location.pathname.split('/').pop();
      fetchShowByQRID(qrid);
    });

    return {
      showDetails,
      formatTimestamp,
      displayDetails,
      isActive,
      willStartSoon,
      timeUntilStart,
    };
  }
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
p {
  margin: 0.5rem 0;
}
</style>
