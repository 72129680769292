<template>
  <div id="top" class="content">
    <Navbar />
    <div id="mainview">
      <router-view/>
    </div>
    <NavbarFooter />
  </div>
</template>

<script>
// App.vue
import Navbar from './components/Navbar.vue'
import NavbarFooter from './components/NavbarFooter.vue';

export default {
  components: { 
    Navbar,
    NavbarFooter
  }
}
</script>

<style>
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  background-color: var(--twdarkbgd);
}
</style>
