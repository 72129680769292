// /src/store/modules/shows.js
import { 
  getCurrentShowAsync, 
  setCurrentShowAsync,
  parseSong,
  toTitleCase,
  makeSongId
} from '@/services/showServices';
import { 
  subscribeToDocument, 
  updateDocument, 
  saveDocument,
  addDocAutoID,
  doesDocExist,
  fetchDocuments, 
  getTS,
  updateArray,
  deleteDocument,
} from '@/services/firebaseServices';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
// import userDetails from './userDetails';

export default {
  namespaced: true,
  state: {
    showDetails: null,
    showID: null,
    showName: null,
    showVenue: null,
    showCity: null,
    atShow: false,
    isActive: false,
    unsubscribeCurrentShowListener: null,
    error: null,
    newShow: true,
    requestID: null,
    requestPath: null,
    userRequestsForCurrentShow: [],
    pauseRequests: false,
    currentSong: null,
  },
  mutations: {
    SET_SHOW_ID(state, showID) {
      state.showID = showID;
    },
    SET_REQUEST_ID(state, requestID) {
      state.requestID = requestID;
    },
    SET_REQUEST_PATH(state, requestPath) {
      state.requestPath = requestPath;
    },
    SET_USER_REQUESTS(state, requests) {
      console.log('SET_USER_REQUESTS says:', requests);
      state.userRequestsForCurrentShow = requests;
    },
    SET_SHOW_DETAILS(state, details) {
      state.showDetails = details;
      if (details && details.showID) {
        state.showID = details.showID; // Ensure showID is set when showDetails is set
      }
    },
    SET_SHOW_NAME(state, showName) {
      if (!state.showDetails) state.showDetails = {}; // Ensure showDetails object exists
      state.showDetails.showName = showName; // Correctly update the showName property
    },
    SET_SHOW_VENUE(state, showVenue) {
      if (!state.showDetails) state.showDetails = {}; // Ensure showDetails object exists
      state.showDetails.showVenue = showVenue; // Correctly update the showVenue property
    },
    SET_SHOW_CITY(state, showCity) {
      if (!state.showDetails) state.showDetails = {}; // Ensure showDetails object exists
      state.showDetails.showCity = showCity; // Correctly update the showCity property
    },
    SET_AT_SHOW(state, atShow) {
      state.atShow = atShow;
    },
    SET_SHOW_IS_ACTIVE(state, isActive) {
      state.isActive = isActive;
    },    
    SET_CURRENT_SONG(state, currentSong) {
      state.currentSong = currentSong;
    },
    SET_PAUSE_REQUESTS(state, pause) {
      state.pauseRequests = pause;
    },
    CLEAR_UNSUB_CURRENT_SHOW_LISTENER(state) {
      if (state.unsubscribeCurrentShowListener) {
        state.unsubscribeCurrentShowListener();
        state.unsubscribeCurrentShowListener = null;
      }
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
  },
  actions: {
    async fetchCurrentShow({ commit, rootState }, email) {
      let QRID = null;
      if (email && email.length > 10 && email.includes('@')) {
        QRID = email;
      }
      const hostEmail = email || rootState.hosts.hostEmail || null;
      console.log(">>>shows.js fetchCurrentShow hostEmail:",hostEmail);
      if (!hostEmail && !QRID) {
        commit('SET_ERROR', 'No host information available to fetch show info');
        commit('SET_AT_SHOW', false);
        commit('SET_SHOW_IS_ACTIVE', false);
        return;
      }
      try {
        let showDetails = null;
        if(QRID) {
          showDetails = await getCurrentShowAsync(QRID);
        } else {
          showDetails = await getCurrentShowAsync(hostEmail);
        }
        if (showDetails) {
          const now = Timestamp.now();
          const isActive = now.seconds >= showDetails.startTS.seconds && now.seconds <= showDetails.endTS.seconds;
      
          commit('SET_SHOW_DETAILS', showDetails);
          commit('SET_SHOW_ID', showDetails.showID);
          commit('SET_AT_SHOW', isActive);
          commit('SET_SHOW_NAME', showDetails.showName);
          commit('SET_SHOW_VENUE', showDetails.showVenue);
          commit('SET_SHOW_CITY', showDetails.showCity);
          commit('SET_SHOW_IS_ACTIVE', isActive);
        } else {
          commit('SET_AT_SHOW', false);
          commit('SET_SHOW_IS_ACTIVE', false);
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
        console.error("Error fetching show info:", error);
      }
    },
    async fetchActiveShows({ commit }, { city, venue }) {
      console.log(">>>>>>>>>>>>>>>shows.js fetchActiveShows city, venue:",city, venue);
      try {
          const queryConstraints = [];
          const now = Timestamp.now();
          if (city) queryConstraints.push(['showCity', '==', toTitleCase(city)]);
          if (venue) queryConstraints.push(['showVenue', '==', toTitleCase(venue)]);
          queryConstraints.push(['startTS', '<=', now]);
          queryConstraints.push(['endTS', '>=', now]);

          console.log("queryConstraints:", queryConstraints);
      
          const shows = await fetchDocuments('KaraokeShows', queryConstraints);
          return shows.map(show => ({
              hostName: show.hostName,
              hostEmail: show.hostEmail,
              showName: show.showName,
              showVenue: show.showVenue,
              isActive: true,
              showID: show.showID,
              showDetails: show
          }));
      } catch (error) {
          console.error('Error fetching active shows:', error.message);
          commit('SET_ERROR', error.message);
          return [];
      }
    },
    async createNewShow({ commit }, { showDetails, QRID }) {
      try {
        showDetails.startTS = getTS(moment(showDetails.startsAt).format('YYYY-MM-DD HH:mm:ss'), 0);
        showDetails.endTS = getTS(moment(showDetails.endsAt).format('YYYY-MM-DD HH:mm:ss'), 0);
        if (QRID) {
          showDetails.QRID = QRID;
        }
  
        console.log('Creating New Show with startTS:', showDetails.startTS);
        console.log('Creating New Show with endTS:', showDetails.endTS);

        await setCurrentShowAsync(showDetails.hostEmail, showDetails);
        commit('SET_SHOW_DETAILS', showDetails);
        commit('SET_SHOW_ID', showDetails.showID);
        if (QRID) {
          await saveDocument(`QRID/${QRID}`, showDetails);
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
        console.error('Error creating new show:', error);
      }
    },
    async fetchShowByQRID({ commit }, QRID) {
      try {
        const showDetails = await getCurrentShowAsync(QRID);
        if (showDetails) {
          commit('SET_SHOW_DETAILS', showDetails);
          commit('SET_SHOW_ID', showDetails.showID);
        } else {
          commit('SET_SHOW_DETAILS', null);
          commit('SET_SHOW_ID', null);
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
        console.error('Error fetching show by QRID:', error);
      }
    },
    async submitSongRequest({commit, rootGetters, state }, payload) {
      const { song, singerName } = payload;
      const user = rootGetters['auth/getUser'];
      const songID = makeSongId(song);
      const showDetails = state.showDetails;
      commit('SET_SHOW_ID', showDetails.showID);
      const hostEmail = showDetails.hostEmail;
      const { artist, title } = parseSong(song);

      const requestObj = {};
      const userSingerName = rootGetters['userDetails/singerName'];
      if (singerName != userSingerName){
        requestObj.guestSinger = true;
      } else {
        requestObj.guestSinger = false;
      }
      
      console.log("shows.js submitSongRequest song, singerName:",song, singerName);
      console.log("shows.js submitSongRequest hostEmail", hostEmail);
      console.log("shows.js submitSongRequest user.email", user?.email);
      console.log("shows.js submitSongRequest songID", songID);
      console.log("shows.js submitSongRequest showDetails", showDetails);
      console.log("shows.js submitSongRequest artist, title", artist, title);
      console.log("shows.js submitSongRequest userSingerName", userSingerName);
      console.log("shows.js submitSongRequest state.showID", showDetails.showID);
      console.log("shows.js submitSongRequest showDetails.hostName", showDetails.hostName);
      console.log("shows.js submitSongRequest showDetails.hostEmail", showDetails.hostEmail);

      let reqDocRef, reqDocPath, requestPath;

      if(!hostEmail || !showDetails.showID || !songID || !user.email) {
        commit('SET_ERROR', "Required information is missing.");
        return;
      }

      requestObj.song = song;
      requestObj.songID = songID;
      requestObj.artist = artist;
      requestObj.title = title;
      requestObj.userEmail = user.email;
      requestObj.singerName = singerName;
      requestObj.hostName = showDetails.hostName;
      requestObj.hostEmail = showDetails.hostEmail;
      requestObj.showVenue = showDetails.showVenue;
      requestObj.showCity = showDetails.showCity;
      requestObj.showID = showDetails.showID;
      requestObj.showName = showDetails.showName;
      requestObj.showPath = showDetails.showPath;
      requestObj.sang = false;
      requestObj.skip = false;
      requestObj.present = true;
      requestObj.sangAt = null;
      requestObj.hasRequests = true;
      
      try {
        // if it's new show, save show data
        const showPath = `KaraokeShows/${showDetails.showID}`;
        if(state.newShow) {
          await saveDocument(showPath, { ...showDetails }, {}, 'showDate');
          state.newShow = false;
        }

        // Prepare and save the request to Firestore
        requestPath = `KaraokeShows/${showDetails.showID}/Requests`;
        reqDocRef = await addDocAutoID(requestPath, requestObj, 'createdAt');

        console.log('requestPath:',requestPath);
        reqDocPath = `${requestPath}/${reqDocRef.id}`;
        
        commit('SET_REQUEST_ID', reqDocRef.id);
        commit('SET_REQUEST_PATH', reqDocPath);

        requestObj.myRequests = [{
          date: new Date(),
          path:reqDocPath,
          reqID:reqDocRef.id,
          showID: showDetails.showID,
        }];

        // save each request a singer makes to the singers MyRequests. Use same ID as above
        const singerRequestPath = `Singers/${user.email}/MyRequests/${reqDocRef.id}`;
        await saveDocument(singerRequestPath, requestObj, {}, 'requestedOn');

      } catch (error) {
        console.error('Error submitting song request:', error.message);
        commit('SET_ERROR', error.message);
      }

      try{
        // update the singers MyKaraokeList
        const myListSongPath = `Singers/${user.email}/MyKaraokeList/${songID}`;
        const songExists = await doesDocExist(myListSongPath);
        if(!songExists){
          // add missing data that would be set in Save to My List.
          requestObj.genre = [];
          requestObj.myRating = null;
          requestObj.fav = false;
          requestObj.duet = false;
          requestObj.practice = false;
          requestObj.sing = true;
          requestObj.singSoon = true;
          requestObj.myLists = [];
          requestObj.sang = false;
          requestObj.sangAt = [];

          await saveDocument(myListSongPath, requestObj, {}, 'addedOn');
        } else {
          // only add this request from the myRequests object to the existing song.
          await updateArray(myListSongPath,'myRequests',requestObj.myRequests);
        }
        // updateDocument(path, data to update, timestamp name). Just add timestamp.
        await updateDocument(myListSongPath, {}, 'lastRequestedOn');
                
      } catch (error) {
        console.error('save singers request data failed:', error.message);
        commit('SET_ERROR', error.message);
      }

      try{
        const AllRequestsData = {
          songID: requestObj.songID,
          song: requestObj.song,
          artist: requestObj.artist,
          title: requestObj.title,
          ...state.showDetails,
        };
        await addDocAutoID('AllRequests', AllRequestsData);
      } catch (error) {
        console.error('save to AllRequests failed:', error.message);
        commit('SET_ERROR', error.message);
      }
    },
    setupFirestoreListener({ commit, state, rootState }) {
      if (rootState.hosts.hostEmail && !state.unsubscribeCurrentShowListener) {
        const unsubscribe = subscribeToDocument(`Hosts/${rootState.hosts.hostEmail}/Shows/currentShow`, (showData) => {
          commit('SET_SHOW_DETAILS', showData);
          commit('SET_AT_SHOW', !!showData?.showID || false);
          commit('SET_SHOW_IS_ACTIVE', showData.isActive || false);
          commit('SET_PAUSE_REQUESTS', showData.requestsPaused || false);
          commit('CLEAR_ERROR');
        });

        // Store unsubscribe function to call it later
        state.unsubscribeCurrentShowListener = unsubscribe;
      }
    },
    clearUnsubscribeCurrentShowListener({ state }) {
      if (state.unsubscribeCurrentShowListener) {
        state.unsubscribeCurrentShowListener();
        state.unsubscribeCurrentShowListener = null;
      }
    },
    async fetchUserRequestsForCurrentShow({ commit, state, rootState }) {
      // commit('SET_SHOW_ID');
      const showID = state.showID;
      const userEmail = rootState.auth.user?.email;

      console.log("shows.js fetchUserRequestsForCurrentShow showID:",showID);
      console.log("shows.js fetchUserRequestsForCurrentShow userEmail:",userEmail);

      if (!showID || !userEmail) {
        commit('SET_ERROR', 'Missing show ID or user email');
        return;
      }

      const collectionPath = `KaraokeShows/${showID}/Requests`;
      const queries = [
        ["userEmail", "==", userEmail],
        // ["sang", "==", false],
        // ["skip", "==", false]
      ];

      try {
        const userRequests = await fetchDocuments(collectionPath, queries);
        commit('SET_USER_REQUESTS', userRequests); // Make sure you have a mutation for setting user requests
      } catch (error) {
        console.error('Error fetching user requests:', error);
        commit('SET_ERROR', error.message);
      }
    },
    async updateRequest({ commit }, { path, data }) {
      console.log('updateRequest path:',path);
      try {
        await updateDocument(path, data);
        // commit('SOME_MUTATION_TO_UPDATE_LOCAL_STATE', data); // Optional: If you want to update your local Vuex state
      } catch (error) {
        console.error('Error updating request:', error);
        commit('SET_ERROR', error.message); // Assuming you have a mutation to handle errors
      }
    },
    updateCurrentSong({commit}, currentSong) {
      console.log("Updating current song in store:", currentSong);
      commit('SET_CURRENT_SONG', currentSong);
    },
    async deleteRequest({ commit }, { path }) {
      console.log('updateRequest path:',path);
      try {
        await deleteDocument(path);
        // commit('SOME_MUTATION_TO_UPDATE_LOCAL_STATE', data); // Optional: If you want to update your local Vuex state
      } catch (error) {
        console.error('Error updating request:', error);
        commit('SET_ERROR', error.message); // Assuming you have a mutation to handle errors
      }
    },
  },
  getters: {
    showDetails: state => state.showDetails,
    hostEmail: state => state.showDetails?.hostEmail,
    showID: state => state.showID || state.showDetails?.showID,
    showName: state => state.showName,
    showVenue: state => state.showVenue,
    showCity: state => state.showCity,

    atShow: state => state.showDetails ? (new Date() >= new Date(state.showDetails.startTS.seconds * 1000) && new Date() <= new Date(state.showDetails.endTS.seconds * 1000)) : false,
    showIsActive: state => state.isActive,
    currentShowName: state => state.showDetails ? state.showDetails.name : '',
    userRequestsForCurrentShow: state => state.userRequestsForCurrentShow || [],
    getCurrentSong: state => state.currentSong,
    error: state => state.error,
  },
};
