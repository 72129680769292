// /src/store/modules/lists.js
import { fetchDocument, fetchDocuments, updateDocument, deleteDocument } from '@/services/firebaseServices';

// /src/store/modules/lists.js
export default {
  namespaced: true,
  state: {
    userKaraokeList: [],
    userKaraokeRequests: [],
    currentSongDetails: {},
  },
  mutations: {
    SET_USER_KARAOKE_LIST(state, songs) {
      state.userKaraokeList = songs;
    },
    SET_USER_KARAOKE_REQUESTS(state, requests) {
      state.userKaraokeRequests = requests;
    },
    UPDATE_USER_KARAOKE_SONG(state, { songID, updates }) {
      const songIndex = state.userKaraokeList.findIndex(song => song.songID === songID);
      if (songIndex !== -1) {
        Object.assign(state.userKaraokeList[songIndex], updates);
      }
    },
    SET_CURRENT_SONG_DETAILS(state, songDetails) {
      state.currentSongDetails = songDetails; // Ensure you have this state property defined
    },
    REMOVE_SONG_FROM_LIST(state, songID) {
      state.userKaraokeList = state.userKaraokeList.filter(song => song.songID !== songID);
    },
  },
  actions: { // Corrected from 'action' to 'actions'
    async fetchUserKaraokeList({ commit, rootGetters }) {
      const userEmail = rootGetters['auth/userEmail'];
      if (!userEmail) {
        console.error("User email not found");
        return;
      }

      try {
        const path = `/Singers/${userEmail}/MyKaraokeList`;
        const userSongs = await fetchDocuments(path);
        commit('SET_USER_KARAOKE_LIST', userSongs);
      } catch (error) {
        console.error("Error fetching user karaoke list:", error);
      }
    },
    async fetchAllUserKaraokeRequests({ commit, rootGetters }) {
      const userEmail = rootGetters['auth/userEmail'];
      if (!userEmail) {
        console.error("User email not found");
        return;
      }

      try {
        const path = `/Singers/${userEmail}/MyRequests`;
        const userSongs = await fetchDocuments(path);
        commit('SET_USER_KARAOKE_REQUESTS', userSongs);
      } catch (error) {
        console.error("Error fetching user karaoke list:", error);
      }
    },
    async updateUserKaraokeSong({ commit, rootGetters }, { songID, updates }) {
      const userEmail = rootGetters['auth/userEmail'];
      if (!userEmail || !songID) {
        console.error("Missing user email or songID");
        return;
      }

      try {
        const path = `/Singers/${userEmail}/MyKaraokeList/${songID}`;
        await updateDocument(path, updates);
        commit('UPDATE_USER_KARAOKE_SONG', { songID, updates });
      } catch (error) {
        console.error("Error updating user karaoke song:", error);
      }
    },
    async fetchSongList({ commit, rootGetters }, filterBy) {
      const userEmail = rootGetters['auth/userEmail']; // Assuming you have a getter to get the user email
      if (!userEmail) {
        console.error("User email not found");
        return;
      }
  
      try {
        const path = `/Singers/${userEmail}/MyKaraokeList`;
        const songList = await fetchDocuments(path, [[filterBy, '==', true]]);
        commit('SET_USER_KARAOKE_LIST', songList); // You might want a separate mutation/state for songList
      } catch (error) {
        console.error("Error fetching sing soon karaoke list:", error);
      }
    },
    async fetchSongListNot({ commit, rootGetters }, filterBy) {
      const userEmail = rootGetters['auth/userEmail']; // Assuming you have a getter to get the user email
      if (!userEmail) {
        console.error("User email not found");
        return;
      }
  
      try {
        const path = `/Singers/${userEmail}/MyKaraokeList`;
        const songList = await fetchDocuments(path, [[filterBy, '==', false]]);
        commit('SET_USER_KARAOKE_LIST', songList); // You might want a separate mutation/state for songList
      } catch (error) {
        console.error("Error fetching sing soon karaoke list:", error);
      }
    },
    async fetchSongDetails({ commit, rootGetters }, songID) {
      const userEmail = rootGetters['auth/userEmail'];
      if (!userEmail) {
        console.error("User email not found");
        return;
      }
    
      try {
        const path = `/Singers/${userEmail}/MyKaraokeList/${songID}`;
        const songDetails = await fetchDocument(path);
        // Handle the fetched song details as needed, for example:
        commit('SET_CURRENT_SONG_DETAILS', songDetails); 
      } catch (error) {
        console.error("Error fetching song details:", error);
      }
    },
    async deleteUserKaraokeSong({ commit, rootGetters }, songID) {
      try {
        const userEmail = rootGetters['auth/userEmail']; // Example of retrieving user's email from Vuex state
      if (!userEmail) {
        throw new Error("User email not found");
      }
        const path = `Singers/${userEmail}/MyKaraokeList/${songID}`;
        await deleteDocument(path); // Assumes deleteDocument is correctly implemented
        commit('REMOVE_SONG_FROM_LIST', songID);
        console.log('Song successfully deleted');
        // Optionally, navigate the user away from the current view or refresh the list
      } catch (error) {
        console.error('Error deleting song:', error);
        // Optionally, inform the user that the deletion failed
      }
    },
  },
  getters: {
    getUserKaraokeList: (state) => state.userKaraokeList,
    getUserKaraokeRequests: (state) => state.userKaraokeRequests,
    getCurrentSongDetails: (state) => state.currentSongDetails,
  }
};