// src/store/store.js
import { createStore } from 'vuex';
import auth from './modules/auth';
import shows from './modules/shows';
import hosts from './modules/hosts';
import userDetails from './modules/userDetails';
import songSearch from './modules/songSearch';
import notifications from './modules/notifications';
import lists from './modules/lists';
import vdjHistory from './modules/vdjHistory';

const store = createStore({
  modules: {
    auth,
    shows,
    hosts,
    userDetails,
    songSearch,
    notifications,
    lists,
    vdjHistory,
  }
});

export default store;