<template>
  <div class="vdj-history">
    <h1>Karaoke History</h1>
    <div v-if="singers.length">
      <h2>Singers</h2>
      <ul>
        <li v-for="singer in singers" :key="singer" @click="selectSinger(singer)">
          {{ singer }}
        </li>
      </ul>
    </div>
    <div v-if="selectedSinger">
      <h2>Artists</h2>
      <p>Artists: {{ artistCount }}, Songs: {{ songCount }}</p>
      <ul>
        <li v-for="artist in artists" :key="artist">
          <div @click="toggleArtist(artist)">
            {{ artist }}
          </div>
          <ul v-if="isArtistVisible(artist)">
            <li v-for="song in getSongsByArtist(artist)" 
              :key="song.id"  
              :class="{ open: selectedResultIndex === index }"
              @click="showSongSublist(index)">
              {{ song.title }}
              <div v-if="selectedResultIndex === index" class="sublist">
                <SubList @signupSuccess="$emit('signupSuccess')" :result="result" />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import SubList from '@/components/SubList.vue';

export default {
  name: 'VDJ_History',
  data() {
    return {
      selectedSinger: null,
      visibleArtists: {}, // Track which artists' songs are visible
    };
  },
  components: { SubList },
  computed: {
    ...mapGetters('vdjHistory', ['singers', 'artists', 'songs', 'artistCount', 'songCount']),
  },
  methods: {
    ...mapActions('vdjHistory', ['fetchSingers', 'fetchArtists', 'fetchSongs', 'clearArtists', 'clearSongs']),
    selectSinger(singer) {
      this.selectedSinger = singer;
      this.clearArtists();
      this.clearSongs();
      this.fetchArtists(singer);
    },
    toggleArtist(artist) {
      if (this.visibleArtists[artist]) {
        // Plain JavaScript deletion
        delete this.visibleArtists[artist];
      } else {
        // Plain JavaScript setting
        this.visibleArtists[artist] = true;
        this.fetchSongs({ singer: this.selectedSinger, artist });
      }
    },
    isArtistVisible(artist) {
      return !!this.visibleArtists[artist];
    },
    getSongsByArtist(artist) {
      return this.songs.filter(song => song.artist === artist);
    },
  },
  created() {
    this.fetchSingers();
  },
  setup() {
    const selectedResultIndex = ref(null);

    const showSongSublist = (index) => {
      selectedResultIndex.value = selectedResultIndex.value === index ? null : index;
    };

    return {
      selectedResultIndex,
      showSongSublist,
    }
  }
};
</script>

<style scoped>
.vdj-history {
  padding: 20px;
}

.vdj-history h1,
.vdj-history h2 {
  margin-bottom: 10px;
}

.vdj-history ul {
  list-style-type: none;
  padding: 0;
}

.vdj-history li {
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;
  color: var(--twdarkc1);
}

.vdj-history li:hover {
  background-color: var(--btnblue1);
}
</style>
