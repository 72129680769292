<!-- Navbar.vue -->
<template>
  <nav class="navbar">
    <div class="navbar-container">
      <template v-if="authIsReady">
        <!-- Hamburger Menu Icon -->
        <button class="hamburger-menu" @click="showMenu = !showMenu">☰</button>
        <!-- Navbar Content -->
        <div class="navbar-content">
          <h1 v-if="!isAuthenticated">
            <router-link to="/signin">Sign In</router-link>
          </h1>
          <h1 v-if="isAuthenticated && !showMenu && atShow">Karaoke List Maker</h1>
          <h1 v-if="isAuthenticated && !atShow"><router-link @click="showMenu = false" to="/findshow">Find Karaoke!</router-link></h1>
          <ul v-if="showMenu" class="navbar-links" aria-expanded="true">
            <li v-if="!hideButton">
              <router-link @click="showMenu = false" to="/findsongs">Find Songs</router-link>
            </li>
            <li v-if="!atShow">
              <router-link @click="showMenu = false" to="/findshow">Find Show</router-link>
            </li>
            <li v-else>
              <router-link @click="showMenu = false" to="/findshow">Change Show</router-link>
            </li>
            <li v-if="user">
              <router-link @click="showMenu = false" to="/addname">Change Singer Name and Email</router-link>
            </li>
            <li v-if="user">
              <router-link @click="showMenu = false" to="/profile">Profile</router-link>
            </li>
            <li v-if="isAuthenticated && isHost">
              <router-link @click="showMenu = false" to="/host">Host</router-link>
            </li>
            <li v-if="isAuthenticated && isHost">
              <router-link @click="showMenu = false" to="/host/newshow" v-if="$route.name !== 'NewShow'">New Show</router-link>
            </li>
            <!-- Comment out or remove the link to `collectkaraokefiles` -->
            <!-- <li v-if="isAuthenticated && isHost">
              <router-link @click="showMenu = false" to="/host/collectkaraokefiles">Scan HD</router-link>
            </li> -->
            <li v-if="isAuthenticated && isHost">
              <button class="makeBtn" @click="createQRCode">Make QR Code</button>
            </li>
            <li v-if="isAuthenticated && isHost">
              <span v-if="!requestsPaused">
                <button class="makeBtn" @click="togglePauseRequests">Pause Requests</button>
              </span>
              <span v-else>
                <button class="makeBtn" @click="togglePauseRequests">Restart Requests</button>
              </span>
            </li>
            <li v-if="isAuthenticated && isHost">
              <span v-if="atShow">
                <button class="makeBtn" @click="handleEndShow">End Show</button>
              </span>
              <span v-else><button class="makeBtn">No Active Show</button></span>
            </li>
            <li v-if="isAuthenticated && isHost">
              <router-link to="/host/showhistory">History</router-link>
            </li>
            <li v-if="user">
              <button class="makeBtn" type="button" @click="doLogout">Logout</button>            
            </li>
            <li v-else>
              <router-link to="/signin">Sign In</router-link> Unknown User.
            </li>
            <li>
              <router-link @click="showMenu = false" to="/help">Help</router-link>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <IconSearches />
  </nav>
</template>

<script>
// Navbar.vue
import { ref, onUnmounted, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import IconSearches from './IconSearches.vue';
import { isElectron } from '@/utils/electronUtils';

export default {
  name: 'Navbar',
  components: { IconSearches },
  setup() {
    const store = useStore();
    const route = useRoute();
    const showMenu = ref(false);

    const user = computed(() => store.state.auth.user);
    const email = computed(() => user.value?.email);
    // hostEmail is only the current user while host is performing host activities like hosting or Admin stuff.
    const hostEmail = ref(null)
    const isAuthenticated = computed(() => !!user.value);
    const authIsReady = computed(() => store.getters['auth/authIsReady']);
    const isHost = computed(() => store.getters['userDetails/isHost']);
    const atShow = computed(() => store.getters['shows/atShow']);
    const requestsPaused = computed(() => store.getters['shows/requestsPaused']);
    const currentShowName = computed(() => store.getters['shows/currentShowName']);
    const userDetails = computed(() => store.getters['userDetails/userDetails']);
    const qrid = computed(() => store.getters['hosts/getQRID']);
    // let path;

    const createQRCode = async () => {
      // first make sure the userDetails are set and that the user isHost.
      await store.dispatch('userDetails/fetchUserDetails');
      if (!email.value) {
        alert('Please log in to create a QR code.');
        return;
      } 
      if (!isHost.value) {
        alert('isHost failed.');
        return;
      }

      // make sure the host email is set to the current user.
      hostEmail.value = email.value;
      console.log("hostEmail.value:",hostEmail.value);
      await store.dispatch('hosts/updateHostEmail', hostEmail.value);

      // get the current users host doc now that hostEmail is set to user.value.email.
      await store.dispatch('hosts/fetchHostInfo');

      // the computed value for the QRID should now be set.
      if (!qrid.value) {
        alert('Could not fetch QRID. Please try again later.');
        return;
      }

      const url = `https://kjpaul.com/qrid/${qrid.value}`;
      const outputFileName = `kjpaul_qr_code_${qrid.value}.png`;

      if (isElectron()) {
        try {
          // Generate QR code using Electron IPC
          window.api.generateQRCode(url, outputFileName)
            .then(() => {
              window.api.onQRCodeGenerated((output) => alert(`QR Code generated and saved at: ${output}`));
              window.api.onQRCodeError((error) => alert(`Error generating QR Code: ${error}`));
            })
            .catch((error) => {
              alert(`Error generating QR Code: ${error}`);
            });
        } catch (error) {
          alert(`Error generating QR Code: ${error.message}`);
        }
      } else {
        alert('QR code generation is only available in the desktop version.');
      }
    };

    const hideButton = computed(() => {
      return route.path === '/findsongs' || route.path.startsWith('/search/');
    });

    const doLogout = async () => {
      await store.dispatch('auth/doLogout');
    };

    const handleEndShow = async () => {
      await store.dispatch('shows/endShow');
    };

    const togglePauseRequests = async () => {
      await store.dispatch('shows/togglePause');
    };

    onUnmounted(() => {
      store.commit('shows/clearUnsubscribeCurrentShowListener');
    });

    watch(user, (newValue, oldValue) => {
      if (newValue !== null && oldValue === null) {
        // path = `Singers/${email.value}`;
        store.dispatch('userDetails/fetchUserDetails');
      }
    });

    watch(userDetails, (newValue) => {
      if (newValue) {
        console.log('userDetails', userDetails.value);
      }
    });

    watch(isHost, (newValue) => {
      if (newValue) {
        console.log('User is a host');
      }
    });

    onMounted(() => {
      if (email?.value) {
        // path = `Singers/${email.value}`;
        store.dispatch('userDetails/fetchUserDetails');
      }
    });

    return {
      user,
      userDetails,
      isAuthenticated,
      authIsReady,
      isHost,
      atShow,
      requestsPaused,
      currentShowName, 
      showMenu,
      hideButton,
      doLogout,
      handleEndShow,
      togglePauseRequests,
      createQRCode,
    };
  },
};
</script>

<style scoped>
.navbar {
  background-color: var(--twdarkbgd);
  padding: 10px;
}
.navbar-container {
  display: flex;
}
.hamburger-menu {
  position: absolute;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  color: var(--twdarkc1);
  font-size: 24px;
  cursor: pointer;
}
.navbar-content {
  flex-grow: 1;
  text-align: left;
}
.navbar-links {
  list-style: none;
  padding: 0;
  display: none;
  flex-direction: row;
  align-items: left;
  flex-wrap: wrap;
  font-size: 1em;
}
.navbar-links button {
  font-size: 1em;
  background-color: var(--twdarkbgd);
}
.navbar-links li {
  margin: 0 20px;
}
.navbar-content h1 {
  text-align: center;
}

.navbar-links[aria-expanded="true"] {
  display: flex;
}

@media (max-width: 768px) {
  .hamburger-menu {
    margin-right: auto;
  }

  .navbar-content {
    width: 100%;
  }

  .navbar-links {
    width: 100%;
    flex-direction: column;
  }
}
@media (max-width: 430px) {
  .navbar-content h1 {
    font-size: 16px;
  }
}
</style>
