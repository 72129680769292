<template>
  <div v-if="isHost">
    <div id="newShowForm">
      <form>
        <div v-if="showResults" id="showResults">
          <router-link to="/host">Go To Show Manager</router-link>
          <ul>
              <li>Host Name: {{ results.hostName }}</li>
              <li>Host Email: {{ results.hostEmail }}</li>
              <li>Show Name: {{ results.showName }}</li>
              <li>Venue: {{ results.showVenue }}</li>
              <li>City: {{ results.showCity }}</li>
              <li>Start Time: {{ results.startsAt }}</li>
              <li>End Time: {{ results.endsAt }}</li>
              <li>Logged in as: {{ results.userName }}</li>
              <li>Logged in email: {{ results.userEmail }}</li>
              <li>Karaoke provider: {{ results.karaokeProviders }}</li>
          </ul>
          <div class="">
            <input @click="handleReset" type="reset" id="showFormReset" value="Reset All" class="" />
          </div>
        </div>
        <div v-else class="">
          <div class="center m5">
            <strong>Enter show details. All fields are required.</strong>
            <br><br>
          </div>
          <div class="">
            <label for="hostName" class="">Host Name</label>
            <input type="text" name="hostName" v-model="hostName" required />
            <span id="hostNameMsg"></span>
          </div>
          <div class="">
            <label class="">Host Email</label>
            <div>{{ userEmail }}</div>
            <button @click="logout">Logout to switch host login</button>
            <br>
            <br>
          </div>
          <div class="">
            <label for="showName" class="">Show Name</label>
            <input type="text" name="showName" v-model="showName" required />
            <span id="showNameMsg"></span>
          </div>
          <div class="">
            <label for="showVenue" class="">Venue</label>
            <input type="text" name="showVenue" v-model="showVenue" required />  
          </div>
          <div class="">
            <label for="city" class="">City</label>
            <input type="text"  name="city" v-model="showCity" required />  
          </div>
          <div>
            <label for="useToday">Use Today's Date:</label>
            <input type="checkbox" id="useToday" v-model="useTodayDate">
          </div>

          <div v-if="!useTodayDate">
            <label for="customDate">Select Date:</label>
            <input type="date" id="customDate" v-model="customDate">
          </div>
          <div id="showStartTime">
            <label for="showStartTimeHours" class="">Start Time: </label>
            <select v-model="showStartTimeHours" name="showStartTimeHours" id="showStartTimeHours">
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07" selected>7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select> : 
            <select v-model="showStartTimeMinutes" name="showStartTimeMinutes" id="showStartTimeMinutes">
              <option value="00" selected>00</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
            </select>&nbsp;&nbsp;
            <select v-model="showStartTimeAMPM" name="showStartTimeAMPM" id="showStartTimeAMPM">
              <option value="am">AM</option>
              <option value="pm" selected>PM</option>
            </select>
            &nbsp;&nbsp;<label for="duration">Duration:</label> 
            <select v-model="duration" name="duration" id="duration">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8" selected>8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select> hours
            <button @click="handleSubmit">Start New Show!</button>
            <div class="error" v-if="error">{{ error }}</div>
          </div>
        </div>
      </form>
      <button @click="getResults">
        <span v-if="!showResults">Show Results</span>
        <span v-else>Hide Results</span>
      </button>
    </div>
    <div id="singers"></div>
  </div>
  <div v-else>Please log in as a Host.</div>
</template>

<script>
// @/src/views/hosts/NewShowForm.vue
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

// TODO: fix form so it allows editing 
// TODO: add date so host can add a future show. (carefull not to overwrite currentShow!)
export default {
  name: 'NewShowForm',
  setup() {
    const store = useStore();
    
    const error = ref(null);
    const showResults = ref(false); // show/hide results view
    const results = ref({}); // object containing show new details
    
    const user = computed(() => store.state.auth.user);
    const isAuthenticated = computed(() => !!user.value);
    const userEmail = computed(() => user.value?.email);
    const isHost = computed(() => store.getters['userDetails/isHost']);
    const showID = computed(() => store.getters['shows/showID'] || false);// TODO: get this via showServices by creating show!!!
    const karaokeProviders = computed(() => store.getters['hosts/karaokeProviders']);
    const QRID = computed(() => store.getters['auth/userQRID']); // Assuming this getter exists

    const hostEmail = computed(() => store.getters['hosts/hostEmail']);
    // const hostName = computed(() => store.getters['hosts/hostName']  );
    const hostName = ref('');
    const showName = ref('');
    const showVenue = ref('');
    const showCity = ref('');
    const showStartTimeHours = ref('07');
    const showStartTimeMinutes = ref('00');
    const showStartTimeAMPM = ref('pm');
    const duration = ref(4);
    const useTodayDate = ref(true);
    const customDate = ref('');

    onMounted(async () => {
      await store.dispatch('hosts/fetchHostInfo');
      hostName.value = store.getters['hosts/hostName'] || '';
    });

    const handleSubmit = async (event) => {
      await store.dispatch('hosts/updateHostEmail', userEmail);
      event.preventDefault();
      error.value = null;

      if (!isHost.value) {
        error.value = "Please log in using a valid host email.";
        return;
      }

      const showStartTime = getShowStartTime() || new Date();
      const showEndTime = new Date(showStartTime?.getTime() + (duration.value * 60 * 60 * 1000));

      console.log("NewShowForm.vue hostEmail.value:",hostEmail.value);
      console.log("NewShowForm.vue hostName.value:",hostName.value);
      console.log('NewShowForm.vue Parsed Start Time:', moment(showStartTime).format('YYYY-MM-DD HH:mm:ss'));
      console.log('NewShowForm.vue Parsed End Time:', moment(showEndTime).format('YYYY-MM-DD HH:mm:ss'));

      const showDetails = {
        hostEmail: hostEmail.value, 
        hostName: hostName.value, 
        isActive: true,
        requestsPaused: false,
        showID: showID.value,
        showName: showName.value,
        showVenue: showVenue.value,
        showCity: showCity.value,
        startsAt: moment(showStartTime).format('YYYY-MM-DD HH:mm:ss'),
        endsAt: moment(showEndTime).format('YYYY-MM-DD HH:mm:ss'),
        duration: duration.value,
        userEmail: user.value?.email,
        karaokeProviders: karaokeProviders.value,
      };

      try {
        // Dispatch the action to create a new show
        await store.dispatch('hosts/fetchHostInfo');
        await store.dispatch('shows/createNewShow', { showDetails, QRID: QRID.value });    
        await store.dispatch('shows/fetchCurrentShow');    
        
        results.value = await store.getters['shows/showDetails'];
        showResults.value = true;
      } catch (error) {
        console.error('NewShowForm could not createNewShow:', error);
      }
    };

    function getShowStartTime() {
      const dateInput = useTodayDate.value ? moment().format('YYYY-MM-DD') : customDate.value;
      let hours = parseInt(showStartTimeHours.value);
      if (showStartTimeAMPM.value === 'pm' && hours < 12) hours += 12;
      if (showStartTimeAMPM.value === 'am' && hours === 12) hours = 0;

      const timeString = `${dateInput} ${hours}:${showStartTimeMinutes.value}:00`;
      const format = 'YYYY-MM-DD HH:mm:ss';

      const dateTime = moment(timeString, format);

      console.log('Parsed DateTime:', dateTime.toDate());
      
      return dateTime.toDate();
    }

    const handleReset = (event) => {
      event.preventDefault();
      showResults.value = false;
      error.value = null;
    };

    const getResults = async () => {
      try {
        showResults.value = !showResults.value;

        if (showResults.value) {
          await store.dispatch('shows/fetchCurrentShow');            
          results.value = await store.getters['shows/showDetails'];
        }
      } catch (error) {
        error.value = "NewShowForm getResults could not get results.";
        console.log(error.value);
      }
    }

    return {
      user,
      isHost,
      isAuthenticated,
      userEmail,
      hostEmail,
      hostName,
      error,
      showResults,
      results,
      showName,
      showVenue,
      showCity,
      showStartTimeHours,
      showStartTimeMinutes,
      showStartTimeAMPM,
      getShowStartTime,
      handleSubmit,
      handleReset,
      getResults,
      duration,
      useTodayDate,
      customDate,
    };
  },
};
</script>

<style scoped>
form {
    background-color: var(--twdarkbg1);
}
input, button {
    width: 100%;
    color: var(--twdarkbgd);
    background-color: var(--twdarkc1);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
input[type="reset"], input[type="submit"], button {
    background-color: var(--btnblue1);
    font-weight: bold;
}
input[type="reset"]:hover, input[type="submit"]:hover, button:hover {
    background-color: var(--btnblue2);
}

input[type="text"], input[type="date"] {
    color: var(--twdarkc4);
    background-color: var(--twdarkc1);
}
input:focus {
    color: black;
}
button {
    margin-top: 20px;
}
select {
    margin-top: 5px;
    color: var(--twdarkc4);
}
select option {
    color: var(--twdarkc3);
}
#showResults{
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
    border: 1px solid var(--twdarkc3);
}
</style>
