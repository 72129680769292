<template>
  <div>
    <button @click="setGroupingKey('artist')">Group by Artist</button>
    <button @click="setGroupingKey('title')">Group by Title</button>
    <!-- Iterate over grouped songs -->
    <div v-for="(group, key) in groupedSongs" :key="key">
      <!-- Clicking on artist/title toggles the visibility of its songs -->
      <h2 @click="toggleGroup(key)">{{ key }}</h2>
      <ul v-if="isVisibleGroup(key)">
        <li v-for="song in group" :key="song.songID"  @click.stop="toggleSubList(song.songID)">
          {{ song.song }}
          <!-- Sublist toggle button -->
          <!-- <button @click.stop="toggleSubList(song.songID)">Toggle Details</button> -->
          <!-- Sublist component -->
          <div v-if="isVisible(song.songID)">
            <SubList :result="song.song" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// /src/components/lists/ArtisList.vue
import { computed, onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import SubList from '@/components/SubList.vue';

export default {
  components: { SubList },
  setup() {
    const store = useStore();
    const groupingKey = ref('artist');
    const songs = computed(() => store.getters['lists/getUserKaraokeList']);
    const visibleSubLists = reactive(new Set());
    const visibleGroups = reactive(new Set());

    onMounted(async () => {
      await store.dispatch('lists/fetchUserKaraokeList');
    });

    // Compute grouped songs
    const groupedSongs = computed(() => {
      return groupSongs(songs.value, groupingKey.value);
    });

    function toggleSubList(songID) {
      if (visibleSubLists.has(songID)) {
        visibleSubLists.delete(songID);
      } else {
        visibleSubLists.add(songID);
      }
    }

    function isVisible(songID) {
      return visibleSubLists.has(songID);
    }

    function toggleGroup(key) {
      if (visibleGroups.has(key)) {
        visibleGroups.delete(key);
      } else {
        visibleGroups.add(key);
      }
    }

    function isVisibleGroup(key) {
      return visibleGroups.has(key);
    }

    function setGroupingKey(key) {
      groupingKey.value = key;
      visibleSubLists.clear();
      visibleGroups.clear();
    }

    return { 
      songs, 
      groupedSongs, 
      groupingKey,
      toggleSubList,
      toggleGroup,
      isVisible,
      setGroupingKey,
      isVisibleGroup,
    };
  },
};

// Utility function to group songs
function groupSongs(songs, key) {
  return songs.reduce((group, song) => {
    const keyValue = song[key];
    if (!group[keyValue]) {
      group[keyValue] = [];
    }
    group[keyValue].push(song);
    return group;
  }, {});
}

</script>
