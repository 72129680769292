<template>
  <div>
  <!-- QR Show Message or Error Handling -->
  <div v-if="invalidHostEmail">
    Error: Invalid Host Email.
  </div>
  <div v-if="showLoading">
    <img :src="loadingGif" alt="Loading data..." />
  </div>
  <!-- QRID Fetch Handling -->
  <button v-if="QRID && !invalidHostEmail" @click="navigateToQRIDShow(QRID)">
    Go to Current Show
  </button>
</div>
<div v-if="showTemplate">
  <!-- Initial form to find hosts -->
  <div v-if="hostList.length == 0 && !hideForm">
    <form @submit.prevent="findHosts">
      <h3>Where is the Show?</h3>
      <input type="text" v-model="city" placeholder="City">
      <span> or </span>
      <input type="text" v-model="venue" placeholder="Venue">
      <button type="submit">Find Hosts</button> 
    </form>
  </div>

  <div v-if="hideForm">
    <button class="makeBtn" @click="hideForm=!hideForm">Find Show (again)</button>
    <div v-if="hostList.length == 0">
      No current karaoke events found. If you are at a show, talk to the host.
    </div>
  </div>
  <!-- Display hosts and allow selection -->
  <div v-if="hostList.length > 0">
    <h2>Select a host:</h2> 
    <ul>
      <li v-for="(host, index) in hostList" :key="index">
        <button class="makeBtn" @click="fetchCurrentShowDetails(host)">
          Join {{ host.showName }} At {{ host.showVenue }} hosted by {{ host.hostName }}
        </button>
      </li>
    </ul>
  </div>
  <!-- Temporary display of selected host and venue, then show RequestToSing component -->
  <div v-if="tempDisplay?.show">
    <p>{{ tempDisplay.text }}</p>
  </div>
  <div class="loading" v-if="showLoading">
    <img :src="loadingGif" alt="Loading data..."/>
  </div>
  <div v-if="showDetails">
    <RequestToSing :result="currentSong" :class="{ 'needsLogin': !isAuthenticated }" />
    <p v-if="requestStatus">{{ requestStatus }}</p>
  </div>
  <div v-if="invalidHostEmail">
    Error: Invalid Host Email.
  </div>
</div>
<div v-else>
  <p v-if="requestStatus">{{ requestStatus }}</p>
</div>
</template>

<script>
// /src/views/FindShows.vue
import { reactive, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import RequestToSing from '@/components/RequestToSing.vue'

export default {
components: {
  RequestToSing
},
setup() {
  const store = useStore();
  const router = useRouter();
  const QRID = computed(() => store.getters['hosts/getQRID']);
  const userDetails = computed(() => store.getters['userDetails/userDetails'])
  const singerName = computed(() => userDetails.value?.singerName)
  const userEmail = computed(() => store.getters['auth/userEmail'])
  const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
  const hostList = ref([]);
  const city = ref('');
  // const ST = ref('');
  const venue = ref('');
  const showDetails = computed(() => store.getters['shows/showDetails']);
  const showID = computed(() => store.getters['shows/showID'])
  const hostName = ref('')
  const hostCity = ref('')
  // const hostST = ref('')
  const hostVenue = ref('')
  const isHost = ref(false)
  const requestStatus = ref('');
  const tempDisplay = reactive({ show: false, text: '' });
  const currentSong = computed(() => store.getters['shows/getCurrentSong']);
  const showTemplate = ref(true);
  const showLoading = ref(false)
  const loadingGif = '/images/eq.gif';
  const invalidHostEmail = ref(false);
  const hideForm = ref(false)

  console.log("FindShows 1 currentSong:",currentSong.value);
  store.dispatch('userDetails/fetchUserDetails');

  const navigateToQRIDShow = (QRID) => {
    if (QRID) {
      router.push(`/qrid/${QRID}`);
    } else {
      invalidHostEmail.value = true;
    }
  };

  /**
   * TEMP: set host city to Reno and skip form to reduce a step.
   *        Eventually the Geolocation should deal with this step
   *        so the form is a backup plan.
   */
  const tempSolution = async () => {
    try {
      const now = new Date();
      const shows = await store.dispatch('shows/fetchActiveShows', {
        city: 'Reno',
        venue: null,
        currentTime: now
      });
      console.log("shows", shows);
      hostList.value = shows;
      showLoading.value = false;
      console.log(">>>>hostList.value:",hostList.value);
      console.log(">>>>hostList.value?.length:",hostList.value?.length);
      if(hostList.value?.length > 0) {
        hideForm.value = true;
      }
      if(hostList.value?.length === 1) {
        await fetchCurrentShowDetails(hostList.value[0])
        console.log(">>>>showID.value:",showID.value);
      }
    } catch (error) {
      console.error("Error fetching shows:", error);
      showLoading.value = false;
    }
  }
  tempSolution();

  const findHosts = async () => {
    hostList.value = [];
    showLoading.value = true;
    try {
      const now = new Date();
      const shows = await store.dispatch('shows/fetchActiveShows', {
        city: city.value,
        venue: venue.value,
        currentTime: now
      });
      console.log("shows", shows);
      hostList.value = shows;
      showLoading.value = false;
      city.value = '';
      venue.value = '';
      hideForm.value = true;
    } catch (error) {
      console.error("Error fetching shows:", error);
      showLoading.value = false;
    }
  };
  
  const fetchCurrentShowDetails = async (currentHost) => {
    console.log("fetchCurrentShowDetails currentHost.hostEmail:",currentHost.hostEmail);
    if (currentHost.hostEmail) {
      showTemplate.value = true;
      invalidHostEmail.value = false;
      showLoading.value = true;
      await store.dispatch('hosts/updateHostEmail', currentHost.email);
      await store.dispatch('hosts/updateCurrentHost', currentHost);
      await store.dispatch('shows/fetchCurrentShow', currentHost.hostEmail);
      tempDisplay.text = `Connecting to show at ${currentHost.venue} hosted by ${currentHost.hostName}...`;
      tempDisplay.show = true;
      console.log("fetchCurrentShowDetails currentSong.value:",currentSong.value);
      if (currentSong.value) {
        store.dispatch('shows/submitSongRequest', { song: currentSong.value, singerName: singerName.value });
      }
      setTimeout(() => {
        tempDisplay.show = false;
        showTemplate.value = false;
        showLoading.value = false;
      }, 3000);
    } else {
      invalidHostEmail.value = true;
    }
  };

  const requestSong = (songReq) => {
    // Assuming 'submitSongRequest' is the correct action in your 'shows' Vuex module
    store.dispatch('shows/submitSongRequest', {
      song: songReq,
      singerName: singerName.value  // Assuming the singer's name is stored in the state
    }).then(() => {
      requestStatus.value = 'Request submitted successfully';
    }).catch(error => {
      requestStatus.value = `Error submitting request: ${error.message}`;
    });
  };

  // Interval to check active show
  onMounted(() => {
    const interval = setInterval(() => {
      store.dispatch('shows/fetchCurrentShow');
    }, 60000); // Check every minute

    onBeforeUnmount(() => {
      clearInterval(interval);
    });
  });

  return {
    isAuthenticated,
    city,
    // ST,
    venue,
    findHosts,
    hostList,
    fetchCurrentShowDetails,
    showDetails,
    isHost,
    userEmail,
    hostName,
    hostCity,
    // hostST,
    hostVenue,
    requestStatus,
    requestSong,
    tempDisplay,
    currentSong,
    showTemplate,
    showLoading,
    loadingGif,
    invalidHostEmail,
    hideForm,
    navigateToQRIDShow,
    QRID,
  };
}
};
</script>

<style scoped>
form {
  margin: 10px;
}
form h3, form p, form span {
  color: var(--primary);
}
form p {
  margin-top: 5px;
}
form button {
  padding: 5px;
}
table {
  border: 1px solid var(--twdarkc2);
  padding: 10px;
  background-color: var(--twdarkbgl);
  border-radius: 10px;
  margin-left: 10px;
}
</style>