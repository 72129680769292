<template>
  <div>
    <h1>Songs by Rating</h1>
    <!-- Iterate over grouped songs by rating -->
    <div v-for="(group, key) in groupedSongsByRating" :key="key">
      <!-- Clicking on rating toggles the visibility of its songs -->
      <h2 @click="toggleGroup(key)">Rating: {{ key || 'Unrated' }}</h2>
      <ul v-if="isVisibleGroup(key)">
        <li v-for="song in group" :key="song.songID">
          {{ song.artist }} - {{ song.title }}
          <!-- Sublist toggle button -->
          <button @click.stop="toggleSubList(song.songID)">Toggle Details</button>
          <!-- Sublist component -->
          <div v-if="isVisible(song.songID)">
            <SubList :result="song.song" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// /src/components/lists/RateList.vue
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import SubList from '@/components/SubList.vue';

export default {
  components: { SubList },
  setup() {
    const store = useStore();
    const songs = computed(() => store.getters['lists/getUserKaraokeList']);
    const visibleSubLists = reactive(new Set());
    const visibleGroups = reactive(new Set());

    onMounted(async () => {
      await store.dispatch('lists/fetchUserKaraokeList');
    });

    // Compute grouped songs by rating
    const groupedSongsByRating = computed(() => {
      return groupSongsByRating(songs.value);
    });

    function toggleSubList(songID) {
      if (visibleSubLists.has(songID)) {
        visibleSubLists.delete(songID);
      } else {
        visibleSubLists.add(songID);
      }
    }

    function isVisible(songID) {
      return visibleSubLists.has(songID);
    }

    function toggleGroup(key) {
      if (visibleGroups.has(key)) {
        visibleGroups.delete(key);
      } else {
        visibleGroups.add(key);
      }
    }

    function isVisibleGroup(key) {
      return visibleGroups.has(key);
    }

    return { 
      songs, 
      groupedSongsByRating,
      toggleSubList,
      isVisible,
      toggleGroup,
      isVisibleGroup,
    };
  },
};

// Utility function to group songs by rating
function groupSongsByRating(songs) {
  const groups = songs.reduce((acc, song) => {
    // Treat null ratings as 'Unrated'
    const ratingKey = song.myRating === null ? 'Unrated' : `Rating ${song.myRating}`;
    if (!acc[ratingKey]) {
      acc[ratingKey] = [];
    }
    acc[ratingKey].push(song);
    return acc;
  }, {});

  // Sort groups by rating (treat 'Unrated' as 0 for sorting)
  const sortedGroups = Object.keys(groups).sort((a, b) => {
    const ratingA = a === 'Unrated' ? 0 : parseInt(a.replace('Rating ', ''));
    const ratingB = b === 'Unrated' ? 0 : parseInt(b.replace('Rating ', ''));
    return ratingA - ratingB;
  }).reduce((acc, key) => {
    acc[key] = groups[key];
    return acc;
  }, {});

  return sortedGroups;
}
</script>
