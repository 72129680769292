<template>
  <div>
    <form @submit.prevent="login" ref="loginForm" id="loginForm">
      <h3>Login</h3>

      <p>Please enter a singer name:</p>
      <p><input type="text" name="newSingerName" placeholder="Update Singer Name (Optional)" v-model="newSingerName"></p>
      
      <p><input type="email" name="email" placeholder="Email (required)" id="loginFormEmail" v-model="email" required></p>
      <p><input type="password" name="password" placeholder="Password" v-model="password" required></p>

      <p>
        <button class="login-action" v-if="!isPending">Log in with Email</button>
        <button class="login-action" v-if="isPending" disabled>Loading...</button> 
        <button type="button" @click="resetPassword" class="forgot-password login-action fr">Forgot Password?</button>
      </p>
      <div v-if="error" class="error">{{ error }}</div>
    </form>
  </div>
</template>

<script>
// Login.vue
// import { ref, computed, nextTick, onMounted, watch } from 'vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {

  setup() {
    const store = useStore();
    const email = ref('');
    const password = ref('');
    const authError = computed(() => store.state.auth.error);
    const isPending = ref(false);
    const error = ref(null);
    const loginForm = ref(null);
    const newSingerName = ref('')

    const login = async () => {
      isPending.value = true;
      error.value = null; // Reset previous errors
      try {
        const emailLowercase = email.value.toLowerCase();
        await store.dispatch('auth/login', { email: emailLowercase, password: password.value });

        // Check if there's a new singer name provided
        if (newSingerName.value) {
          await store.dispatch('userDetails/updateUserDetails', {
            email: emailLowercase,
            userDetails: {
              singerName: newSingerName.value
            }
          });
          console.log('New singer name set:', newSingerName.value);
        }

        isPending.value = false;
        email.value = '';
        password.value = '';
        newSingerName.value = ''; // Reset the singer name field
      } catch (err) {
        error.value = err.message;
        console.error('Login failed:', err.message);
        isPending.value = false;
      }
    };

    // Function to handle password reset
    const resetPassword = async () => {
      if (email.value) {
        try {
          await store.dispatch('auth/resetPassword', email.value);
          alert("Password reset link has been sent to your email.");
        } catch (err) {
          error.value = "Failed to send password reset email.";
          console.error(err);
        }
      } else {
        error.value = "Please enter your email address.";
      }
    };

    setTimeout(() => {
      document.getElementById("loginFormEmail").focus();
    }, 500)

    return { 
      email, 
      password, 
      newSingerName,
      login, 
      authError, 
      isPending, 
      error, 
      resetPassword,
      loginForm,
    };
  }
}
</script>

<style scoped>
  h3, p { color: var(--twdarkbgm);}
  p { 
    margin-bottom: 5px;
  }
  input { 
    border: solid 1px var(--twdarkbgl);
    width: 80%;
  }
  .login-actions { margin-top: 8px; }
</style>