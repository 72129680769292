<template>
  <div>
    <h1>25 Random Songs</h1>
    <button class="makeBtn" @click="fetchRandomSongs">Get 25 New Random Songs</button>
    <div class="hint">(try "More Options -> More songs by Artist")</div>
    <ul v-if="songs.length">
      <li v-for="(song, index) in songs" :key="index" @click="toggleSubList(index)">
        {{ song.name }}
        <div v-if="song.showSubList">
          <SubList :result="song.name" />
        </div>
      </li>
    </ul>
    <div v-else>No songs in your karaoke list.</div>
  </div>
</template>

<script>
import { onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import SubList from '@/components/SubList.vue';



export default {
  components: { 
    SubList,
 },
  setup() {
    const store = useStore();
    const songs = ref([]);

    const fetchRandomSongs = () => {
      store.dispatch('songSearch/fetch25RandomSongs');
    };

    onMounted(fetchRandomSongs);

    const toggleSubList = (index) => {
      // Correctly toggling showSubList for deep reactivity
      songs.value = songs.value.map((song, i) => {
        if (i === index) {
          return { ...song, showSubList: !song.showSubList };
        }
        return song;
      });
    };

    watch(() => store.getters['songSearch/getSearchResults'], (newSongs) => {
      songs.value = newSongs.map(song => ({
        name: song,
        showSubList: false,
      }));
    }, { immediate: true });

    return { 
      songs,
      toggleSubList,
      fetchRandomSongs,
    };
  },
};
</script>


<style>
  .hint {
    font-size: small;
    color: var(--warning2);
  }
</style>