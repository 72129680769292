<template>
  <div>
    <h1>Show History</h1>
    <div v-if="!selectedShow">
      <ol>
        <li v-for="show in showHistory" :key="show.showID" >
          {{ show.showName }} - {{ show.showVenue }} - {{ show.startsAt }}
          <button class="makeBtn" @click="fetchRequests(show.showID)">View Show</button>
          <button class="makeBtn" @click="deleteShow(show.showID)">Delete Show</button>
        </li>
      </ol>
    </div>
    <div v-else>
      <h2>Requests for {{ selectedShowName }}</h2>
      <div class="loading" v-if="showLoading">
        <img :src="loadingGif" alt="Loading data..."/>
      </div>
      <div v-else>
        <button class="makeBtn" @click="selectedShow=false">Select a dfferent show</button>
        <button class="makeBtn" @click="deleteShow(selectedShowID)">Delete Show</button>
      </div>
      <ol>
        <li v-for="request in requestList2" :key="request.id">
          <div v-if="request.sangAt">
            Sang at {{ formatTimestampToTime(request.sangAt) }}: &nbsp;&nbsp;&nbsp; {{ request.singerName }} - {{ request.artist }} - {{ request.title }}
            <button class="makeBtn" @click="copyToClipboard(toTitleCase(`${request.singerName} - ${request.song}`))">Copy</button>
          </div>
          <div v-else>
            Requested at {{ formatTimestampToTime(request.createdAt) }}: &nbsp;&nbsp;&nbsp; {{ request.singerName }} - {{ request.artist }} - {{ request.title }}
            <button class="makeBtn" @click="copyToClipboard(toTitleCase(`${request.singerName} - ${request.song}`))">Copy</button>
          </div>
        </li>
      </ol>
      <button class="makeBtn" @click="selectedShow=false">Select a dfferent show</button>
    </div>
  </div>
</template>

<script>
import { watch, computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const userEmail = store.getters['auth/userEmail'];
    // const showHistory = computed(() => {
    //   console.log("Recomputing shows:", store.getters['hosts/getShowHistory'].value);
    //   return store.getters['hosts/getShowHistory'];
    // });
    // const showHistory = computed(() => store.getters['hosts/getShowHistory']);
    const showHistory = computed(() => {
      const shows = store.getters['hosts/getShowHistory'];
      // Convert 'startsAt' string to Date object and sort
      return shows.sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt));
    });
    const selectedShowName = ref("");
    const selectedShowID = ref("");
    const selectedShowRequests = ref([]);
    const selectedShow = ref(false)
    const selectedShowData = ref(null)
    const showLoading = ref(false)
    const loadingGif = '/images/eq.gif';

    // Fetch showHistory on mount
    onMounted(() => {
      store.dispatch('hosts/fetchShows', userEmail);
    });

    // Watch the computed property 'shows' for changes
    watch(showHistory, (newShowHistory, oldShowHistory) => {
      console.log('Shows updated:', newShowHistory);
      if (newShowHistory.length !== oldShowHistory.length) {
        console.log('Number of shows has changed:', newShowHistory.length);
      }
    });

    let requestList
    const requestList2 = ref(null)
    const fetchRequests = async (showID) => {
      selectedShow.value = true
      selectedShowData.value = showHistory.value.find(show => show.showID === showID);
      await store.dispatch('hosts/fetchRequests', showID);
      requestList = computed(() => store.getters['hosts/getRequests']);
      console.log("requestList", requestList.value);
      selectedShowName.value = selectedShowData.value.showName
      selectedShowID.value = selectedShowData.value.showID
      showLoading.value = true
      setTimeout(() => {
        requestList2.value = sortedRequests(requestList.value)
        showLoading.value = false
      }, 2000)
    };

    // Computed property to sort requests based on sangAt
    const sortedRequests = (requests) => {

      if (!requests) return [];

      // Split and sort requests
      const sangRequests = requests.filter(req => req.sangAt).sort((a, b) => a.sangAt.seconds - b.sangAt.seconds);
      const unsangRequests = requests.filter(req => !req.sangAt);
      return [...sangRequests, ...unsangRequests];
    };

    const copyToClipboard = async (songArtistTitle) => {
      try {
        await navigator.clipboard.writeText(songArtistTitle);
        // You can add more user feedback here (like a tooltip or a small popup)
      } catch (err) {
        console.error("Failed to copy:", err);
      }
    }

    const deleteShow = async (showID) => {
      // Ask for confirmation before deleting
      if (confirm('Are you sure you want to delete this show? This action cannot be undone.')) {
        try {
          const showPath = `Hosts/${userEmail}/Shows/${showID}`;
          await store.dispatch('hosts/deleteShow', { path: showPath, showID });  // Dispatch an action to delete the show
          console.log("Show deleted successfully");
          selectedShow.value = false;
        } catch (error) {
          console.error("Error deleting the show:", error);
          alert('Failed to delete the show. Please try again.');
        }
      }
    };

    const formatDate = (date) => new Date(date).toLocaleDateString();
    const formatTime = (date) => new Date(date).toLocaleTimeString();

    function formatTimestampToTime(timestamp) {
      if (!timestamp || !timestamp.seconds) {
          console.error("Invalid timestamp:", timestamp);
          return ""; // Return an empty string if the timestamp is invalid
      }

      // Convert Firestore Timestamp to JavaScript Date object
      const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

      // Extract hours and minutes and format them
      let hours = date.getHours();
      const minutes = date.getMinutes();
      
      // Convert hours from 24-hour to 12-hour format if needed
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'

      // Ensure minutes are two digits
      const minutesPadded = minutes < 10 ? '0' + minutes : minutes;

      // Format the time in hh:mm format
      return `${hours}:${minutesPadded} ${period}`;
    }

    const toTitleCase = (str) => {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    }

    return {
      showHistory,
      fetchRequests,
      selectedShowRequests,
      selectedShowName,
      selectedShowID,
      formatDate,
      formatTime,
      selectedShow,
      selectedShowData,
      requestList,
      requestList2,
      sortedRequests,
      formatTimestampToTime,
      showLoading,
      loadingGif,
      copyToClipboard,
      deleteShow,
      toTitleCase,
    };
  }
};
</script>

<style scoped>
li {
  cursor: pointer;
}
.makeBtn:active {
  background-color: var(--myYellow);
}
.makeBtn {
  margin-left: 20px;
}
</style>
