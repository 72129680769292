// /src/main.js
import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './assets/main.css'
import { auth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

let appInitialized = false;

onAuthStateChanged(auth, async () => {
  if (!appInitialized) {
    // Ensure auth state is initialized in Vuex before creating the app
    await store.dispatch('auth/initializeAuthState');
    
    const app = createApp({
      render: () => h(App)
    });

    app.use(router);
    app.use(store);
    app.use(Toast, {
      position: 'top-right',
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false
    });

    app.mount('#app');
    appInitialized = true;
  }
});
