<template>
  <div>
    <div v-if="!isAuthenticated" class="noUserWarning needsLogin">
      Unknown user: To save songs to your list or request to sing, we need to know who you are.
      Clicking these buttons will bring up the Sign In form. It's quick and easy!
    </div>
    <div v-if="!pauseRequests">
      <RequestToSing :result="result" :class="{ 'needsLogin': !isAuthenticated }" />
    </div>
    <div v-else-if="pauseRequests">
      <p>Requests Paused</p>
    </div>
    <MoreOptions :result="result" />
  </div>
</template>

<script>
// import { computed, ref } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import RequestToSing from '../components/RequestToSing.vue'
import MoreOptions from './MoreOptions.vue';

export default {
  name: 'SubList',
  emits: ['signupSuccess'],
  props: ['result'],
  components: {
    RequestToSing,
    MoreOptions,
  },
  setup(props) {
    const store = useStore();
    const pauseRequests = computed(() => store.state.shows.pauseRequests);
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);

    console.log("'updateCurrentSong', props.result:", props.result);
    store.dispatch('shows/updateCurrentSong', props.result);

    return {
      isAuthenticated,
      pauseRequests,
    };
  },
};
</script>

<style scoped>
.noUserWarning {
  font-size: 14px;
  color: var(--warning2);
  padding: 5px;
}
.needsLogin {
  background-color: var(--twdarkbgl2);
}
</style>
