<template>
  <div>
    <div v-if="!user">
      Unknown User, please  
      <a href='#' @click="toggleLogin">Log-in</a> or
      <a href='#' @click="toggleSignup">Signup</a> or use 
      <a href='#' @click.prevent="signInWithGoogle">Google Auth</a>
    
      <Login v-if="showLogin"></Login>
      <Signup v-if="showSignup"></Signup>
    </div>  
    <div v-else>
      <div>
        You are logged in as {{ user.email }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import Login from '@/views/auth/Login.vue';
import Signup from '@/views/auth/Signup.vue';

/**
 * // TODO To make the single form happen, I don't need to pass the event back down to 
 * the caller, instead, I just need to pass the type event and the song to this form.
 * Then the action can be handeled here. It's not a perfect plan, but it is easier than
 * what I was trying ot do before.
 */

export default {
  name: 'SignIn',
  components: {
    Login,
    Signup
  },
  setup() {
    const store = useStore();
    const showLogin = ref(false);
    const showSignup = ref(false);

    const user = computed(() => store.state.auth.user);

    const toggleLogin = () => {
      showLogin.value = !showLogin.value;
      showSignup.value = false; // Close signup form when login is toggled
    };

    const toggleSignup = () => {
      showSignup.value = !showSignup.value;
      showLogin.value = false; // Close login form when signup is toggled
    };

    const signInWithGoogle = async () => {
      try {
        await store.dispatch('auth/signInWithGoogle');
      } catch (error) {
        console.error('Google sign-in failed:', error.message);
      }
    };

    return {
      user,
      showLogin,
      showSignup,
      toggleLogin,
      toggleSignup,
      signInWithGoogle
    };
  }
}
</script>

<style scoped>
  h3 { color: var(--twdarkbgm);}
  input { border: solid 1px var(--twdarkbgl)}

</style>