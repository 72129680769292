<template>
  <div class="list-categories center">
    <button class="makeBtn" v-for="(category, index) in categories" :key="index" @click="selectCategory(category.key)">
      {{ category.label }}
    </button>
  </div>
  <div class="icon-container" v-if="selectedCategory">
    <div class="icon-group">
      <div v-for="item in filteredListItems" :key="item.type" @click="selectList(item.type)" class="icon-item">
        <span class="material-icons" :style="{color: item.color}">{{ item.icon }}</span>
        <p>{{ item.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconLists',
  data() {
    return {
      categories: [
        { key: 'listTypes', label: 'List Types', words: ['all', 'favorites', 'artists', 'rate'] },
        { key: 'singingActivities', label: 'Singing Activities', words: ['sing', 'duets', 'sang', 'practice', 'singsoon'] },
        { key: 'customGroupings', label: 'Custom Groupings', words: ['unsung', 'requests', 'custom', 'language', 'genre'] },
      ],
      listItems: [
        { type: 'all', icon: 'home', color: 'blue', label: 'All' },
        { type: 'favorites', icon: 'favorite', color: 'red', label: 'Favorites' },
        { type: 'artists', icon: 'palette', color: 'darkcyan', label: 'Artist' },
        { type: 'rate', icon: 'star', color: 'darkorange', label: 'Rate' },
        { type: 'sing', icon: 'mic', color: '#fc5e03', label: 'Sing' },
        { type: 'duets', icon: 'interpreter_mode', color: 'cornflowerblue', label: 'Duets' },
        { type: 'sang', icon: 'mic_external_on', color: 'blueviolet', label: 'Sang' },
        { type: 'practice', icon: 'school', color: 'purple', label: 'Practice' },
        { type: 'singsoon', icon: 'electric_bolt', color: 'orange', label: 'Sing Soon' },
        { type: 'unsung', icon: 'mic_external_off', color: 'darkslategrey', label: 'Unsung' },
        { type: 'requests', icon: 'mail', color: 'green', label: 'Requests' },
        { type: 'custom', icon: 'list', color: 'brown', label: 'Custom' },
        { type: 'language', icon: 'language', color: 'red', label: 'Language' },
        { type: 'genre', icon: 'music_note', color: 'indianred', label: 'Genre' },
      ],
      selectedCategory: null,
    };
  },
  computed: {
    filteredListItems() {
      if (!this.selectedCategory) return [];
      const category = this.categories.find(c => c.key === this.selectedCategory);
      if (!category) return [];
      return this.listItems.filter(item => category.words.includes(item.type));
    },
  },
  methods: {
    selectCategory(categoryKey) {
      this.selectedCategory = this.selectedCategory === categoryKey ? null : categoryKey;
    },
    selectList(listType) {
      this.$emit('listSelected', listType);
    },
  },
};
</script>

<style scoped>
.icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}

.icon-container a {
  background-color: transparent;
}

.icon-group div {
  flex: content;
  justify-content: space-between;
  display: inline-block;
  margin: 0 20px;
}
</style>
