<template>
  <div class="search-container">
    <h2>To find a song input partial words.</h2>
    <div class="input-wrapper">
      <input 
        id="songSearchInput" 
        name="songSearchInput"
        v-model="searchTerm" 
        @keyup.enter="handleEnterKey" 
        spellcheck="false" 
        placeholder="Enter Artist and or Song Title here..." />
      <span class="material-icons search-clear" v-if="searchTerm" @click="clearSearch">cancel</span>
    </div>
    <div class="loading" v-if="isLoading">
      <img :src="loadingGif" alt="Loading data..." />
      <p>Loading data...</p>
    </div>
    <div v-if="!isLoading">
      <div v-if="searchResults.length">
        <h2>Search Results:</h2>
        <ul>
          <li v-for="(result, index) in searchResults" 
            :key="index" @click="showSongSublist(index)" 
            :class="{ open: selectedResultIndex === index }">
            {{ result }}
            <div v-if="selectedResultIndex === index" class="sublist">
              <SubList @signupSuccess="$emit('signupSuccess')" :result="result" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex'; 
import SubList from './SubList.vue';

export default {
  name: 'JsonSearchComponent',
  components: { SubList },
  props: {
    initialSearchTerm: String,
  },
  setup(props) {
    const store = useStore();
    const searchTerm = ref(props.initialSearchTerm || '');
    const selectedResultIndex = ref(null);
    const loadingGif = '/images/eq.gif';

    const searchResults = computed(() => store.getters['songSearch/getSearchResults']);
    const isLoading = computed(() => store.state.songSearch.isLoading);

    const performSearch = () => {
      if (searchTerm.value.length > 2) {
        store.dispatch('songSearch/searchKaraokeSongs', searchTerm.value);
      }
    };

    const clearSearch = () => {
      searchTerm.value = '';
    };

    const showSongSublist = (index) => {
      selectedResultIndex.value = selectedResultIndex.value === index ? null : index;
    };

    const handleEnterKey = () => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
    };

    watch(searchTerm, (newValue) => {
      if (newValue.length > 2) {
        performSearch();
      } else {
        store.commit('songSearch/CLEAR_SEARCH_RESULTS');
      }
    }, { immediate: true });

    onMounted(() => {
      if (props.initialSearchTerm) {
        performSearch();
      }
    });

    return {
      searchTerm,
      searchResults,
      isLoading,
      selectedResultIndex,
      showSongSublist,
      performSearch,
      clearSearch,
      handleEnterKey,
      loadingGif,
    };
  }
};
</script>

<style scoped>
label {
  font-weight: bold;
}
.search-container {
  position: relative;
}
.input-wrapper {
  position: relative;
  display: flex;
}
#songSearchInput {
  flex-grow: 1;
  color: var(--primary);
  padding-right: 30px;
}
.search-clear {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
}
.loading {
  margin: 10px auto;
  text-align: center;
}
</style>
