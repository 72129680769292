<template>
  <div @click.stop>
    <div class="littleBtn" @click="handleSaveClick" v-if="!submitClicked && !error">
      <span class="material-icons" style="color: mediumorchid;">playlist_add</span>
      <div class="raisedText">{{ buttonText }}</div>
    </div>
    <div v-else>
      <div v-if="!isAuthenticated">
        Unknown User.
        <a href="#" @click="toggleLogin">Log-in</a> or
        <a href="#" @click="toggleSignup">Signup</a> or use
        <a href="#" @click="signInWithGoogle">Google Auth</a>
        to use this function.

        <Login v-if="showLogin" @loginSuccess="handleLoginSuccess"></Login>
        <Signup v-if="showSignup" @signupSuccess="handleLoginSuccess"></Signup>
      </div>
      <div v-if="submitClicked && isAuthenticated">
        <div v-if="error" class="error">{{ error }}</div>
        <div>
          {{ clickMsg }}
          <router-link to="/lists">GoTo My Lists</router-link>
          <ListOptions v-if="showOptions" :songId="savedSongId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Login from '@/views/auth/Login.vue';
import Signup from '@/views/auth/Signup.vue';
import ListOptions from '@/components/lists/ListOptions.vue';

export default {
  name: 'SaveToMyList',
  props: ['result'],
  components: {
    Login,
    Signup,
    ListOptions,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const user = computed(() => store.state.auth.user);
    const isAuthenticated = computed(() => !!user.value);
    const error = ref(null);
    const clickMsg = ref('');
    const submitClicked = ref(false);
    const showLogin = ref(false);
    const showSignup = ref(false);
    const savedSongId = computed(() => store.getters['songSearch/getSongID']);
    const showOptions = ref(false);

    const buttonText = computed(() => {
      if (route.path === '/lists' || route.path.startsWith('/lists/')) {
        return 'Edit Song Options';
      }
      return 'Save to My List';
    });

    const toggleLogin = () => {
      showLogin.value = !showLogin.value;
      showSignup.value = false;
    };

    const toggleSignup = () => {
      showSignup.value = !showSignup.value;
      showLogin.value = false;
    };

    const handleLoginSuccess = () => {
      showLogin.value = false;
      clickMsg.value = 'Loading...';
      handleSaveClick();
    };

    const signInWithGoogle = async () => {
      try {
        await store.dispatch('auth/signInWithGoogle', {
          onSuccess: (user) => {
            clickMsg.value = 'Successfully authenticated. Saving the song...';
            handleSaveClick();
            console.log('Successfully signed in with Google', user.value?.email);
          },
        });
      } catch (err) {
        error.value = `Google Sign-In failed: ${err.message}`;
        console.error('Failed to sign in with Google:', err.message);
      }
    };

    const handleSaveClick = async () => {
      submitClicked.value = true;
      if (isAuthenticated.value) {
        await saveToMyList();
      } else {
        clickMsg.value = 'Please log in to save the song.';
      }
    };

    const saveToMyList = async () => {
      try {
        console.log('saveToMyList props.result', props.result);
        await store.dispatch('songSearch/saveSongToMyList', props.result);
        console.log('Saved Song ID:', savedSongId.value);
        clickMsg.value = 'Song successfully saved.';
        showOptions.value = true;
        error.value = '';
      } catch (err) {
        error.value = `saveToMyList failed: ${err.message}`;
        console.log(error.value);
      }
    };

    watch(isAuthenticated, async (newValue) => {
      if (newValue === true && submitClicked.value) {
        await saveToMyList();
      }
    });

    return {
      user,
      isAuthenticated,
      clickMsg,
      submitClicked,
      error,
      showLogin,
      showSignup,
      savedSongId,
      showOptions,
      buttonText,
      toggleLogin,
      toggleSignup,
      handleSaveClick,
      handleLoginSuccess,
      signInWithGoogle,
    };
  },
};
</script>

<style scoped>
.raisedText {
  margin-left: 20px;
  color: burlywood;
  text-decoration: none;
}
</style>
